export const loadingIndicatorES = {
  audio: 'Cargando audios',
  checkingAnswer: 'Comprobando respuesta',
  content: 'Cargando contenido..',
  login: {
    signing: 'Iniciando sesión'
  },
  message: 'Cargando',
  payment: 'Aptis está procesando su pago',
  processing: 'Procesando registro',
  request: 'AptisGo está procesando su solicitud',
  verificating: 'AptisGo está procesando su verificación',
  results: 'Obteniendo resultados',
  sending: 'Enviando',
  updating: 'Actualizando',
  course: 'Cargando curso'
}
