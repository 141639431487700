export const notificationsEN = {
  markAllAsRead: 'Mark everything as read',
  upToDate: 'You are up to date',
  paymentSuccess: 'El pago ha sido procesado',
  paymentNotify: 'Tu paquete está por expirar',
  paymentExpired: 'Tu paquete ha expirado',
  feedbackSuccess: 'Tienes resultados disponibles',
  evaluationPending: 'Tu evaluación ha sido entregada',
  evaluationCompleted: 'Tu evaluación ha sido corregida',
}
