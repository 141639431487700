const PLANS = {
  APTIS: 'Curso Aptis',
  BLUE: 'Blue',
  DIAMOND: 'Diamond',
  GO: 'Go',
  GOLD: 'Gold',
  GRANDMASTER: 'Grand Master',
  GREEN: 'Green',
  MASTER: 'Master',
  PLATINUM: 'Platinum',
  RUBY: 'Ruby',
  SILVER: 'Silver'
}

export default PLANS
