import React, { memo } from 'react'

import Template from 'components/Template'


const Testing = () => {
  return (
    <Template view />
  )
}

export default memo(Testing)
