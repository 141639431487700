export const settingsEN = {
  cancel: 'Cancel',
  change: 'Switch exam',
  confirm: 'Confirm:',
  edit: 'Edit',
  email: 'Email',
  lastName: 'Last Name',
  model: 'Model',
  name: 'Name',
  password: 'Update Password',
  save: 'Save',
  title: 'Settings',
  update: 'Updated Successfully'
}
