export const meetingsEN = {
  error: 'We had a problem on the server, please join us again.',
  warning: 'Dear user, there are 4 available minutes left for the class to end',
  errors: {
    invalidMeeting: 'The link is invalid or has expired.',
    invalidLink: 'The link has expired or does not exist'
  },
  ending: 'Dear user, your class has finished',
  partial: 'You can now join the call with your ',
  student: 'Student',
  teacher: 'Teacher',
  action: ' Click here!',
  share: 'Share'
}
