import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pricingSelector } from 'store/@selectors/plans'
import { fetchPricingThunk } from 'store/@thunks/plans'
import { initialArguments } from 'hooks'

/**
 * @typedef {Object} UsePlans
 * @property {Array<Plan>} actives
 * @property {Array<Plan>} plans
 * @property {boolean} loading
 */

/**
 * @example
 * const { plans, loading } = usePlans()
 *
 * return plans.map(...statements)
 * @param {useBackendService}
 * @returns {UsePlans}
 */
function usePricing({ preload } = initialArguments) {
  const plans = useSelector(pricingSelector)

  const dispatch = useDispatch()

  const fetchPricing = useCallback(() => {
    const plans = dispatch(fetchPricingThunk())

    return () => {
      plans.abort()
    }
  }, [dispatch])

  useEffect(() => {
    if (preload) {
      const plans = dispatch(fetchPricingThunk())

      return () => {
        plans.abort()
      }
    }
  }, [dispatch, preload])

  return {
    ...plans,
    fetchPricing
  }
}

export default usePricing