const styles = [
  {
    emoji: 'Robot',
    color: '#4312AE'
  },
  {
    emoji: 'Sun',
    color: '#FF7C0C'
  },
  {
    emoji: 'Bee',
    color: '#FFB300'
  },
  {
    emoji: 'Dog',
    color: '#DC3545'
  },
  {
    emoji: 'Pizza',
    color: '#AA26DA'
  },
  {
    emoji: 'Spaceship',
    color: '#FFB5B5'
  },
  {
    emoji: 'Diet',
    color: '#4DD599'
  },
  {
    emoji: 'Octopus',
    color: '#F7FF56'
  },
  {
    emoji: 'Frog',
    color: '#692DB7'
  },
  {
    emoji: 'Flower',
    color: '#DF0054'
  }
]


export default styles