/**
 * @param {import ('store/@reducers/plans').PlansState} state
 * @param {import ('@reduxjs/toolkit').Action} action
 */
export const fetchPlansFullfiledController = (state, action) => {
  const [initial] = action.payload.response

  state.plans.data = action.payload.response
  state.plans.selected = initial
  state.plans.loading = false
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 */
export const fetchPlansRejectedController = state => {
  state.plans.loading = false
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 */
export const fetchPlansPendingController = state => {
  state.plans.loading = true
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 * @param {import ('@reduxjs/toolkit').Action} action
 */
export const fetchOffersFullfiledController = (state, action) => {
  const [initial] = action.payload.response

  state.plans.selected = initial

  state.offers.data = action.payload.response

  state.offers.loading = false
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 */
export const fetchOffersRejectedController = state => {
  state.offers.loading = false
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 */
export const fetchOffersPendingController = state => {
  state.offers.loading = true
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 */
export const fetchPricingPendingController = state => {
  state.pricing.loading = true
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 */
export const fetchPricingFullfiledController = (state, action) => {
  state.pricing.loading = false
  state.pricing.data = action.payload.response
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 */
export const fetchPricingRejectedController = (state, action) => {
  state.pricing.loading = false
  state.pricing.error = action.payload || true
}

/**
 * @param {import ('store/@reducers/plans').PlansState} state
 */
export const selectPlanController = (state, action) => {
  const fromPlans = state.plans.data.find(plan => plan.name === action.payload)

  const fromOffers = state.offers.data.find(plan => plan.name === action.payload)
  
  const fromPricing = state.pricing.data.find(plan => plan.name === action.payload)

  if (fromPlans) {
    state.plans.selected = fromPlans
  }

  if (fromOffers) {
    state.plans.selected = fromOffers
  }

  if (fromPricing) {
    state.plans.selected = fromPricing
  }
}