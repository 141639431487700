export const meetingsES = {
  error: 'Tuvimos un problema con el servidor, por favor vuelva a conectarse.',
  warning: 'Querido usuario, le quedan 4 minutos para que su clase termine',
  errors: {
    invalidMeeting: 'El link es invalido o ha expirado.',
    invalidLink: 'El link ha caducado o no existe'
  },
  ending: 'Querido usuario, su clase ha finalizado.',
  partial: '¡Ya puedes unirte a la llamada con tu ',
  student: 'Estudiante',
  teacher: 'Profesor',
  action: ' ¡Haciendo click aquí!',
  share: 'Compartir'
}
