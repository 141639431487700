const ANIMATIONS = {
  ATTENTION_SEEKER: {
    BOUNCE: 'bounce',
    FLASH: 'flash',
    PULSE: 'pulse',
    RUBBER_BRAND: 'rubberBrand',
    SHAKE: 'shake',
    SWING: 'swing',
    TADA: 'tada'
  },
  BOUNCING_ENTRANCE: {
    BOUNCE_IN: 'bounceIn',
    BOUNCE_IN_DOWN: 'bounceInDown',
    BOUNCE_IN_LEFT: 'bounceInLeft',
    BOUNCE_IN_RIGHT: 'bounceInRight',
    BOUNCE_IN_UP: 'bounceInUp'
  },
  BOUNCING_EXITS: {

  },
  FADING_ENTRANCE: {
    fadeIn: 'fadeIn',
    FADE_IN_DOWN: 'fadeInDown',
    FADE_IN_LEFT: 'fadeInLeft',
    FADE_IN_RIGHT: 'fadeInRight',
    FADE_IN_UP: 'fadeInUp'
  },
  FADING_EXIT: {
    fadeOut: 'fadeOut'
  },
  FLIPPERS: {
    FLIP: 'flip',
    FLIP_IN_X: 'flipInX',
    FLIP_IN_Y: 'flipInY',
    FLIP_OUT_X: 'flipOutX',
    FLIP_OUT_Y: 'flipOutY'
  },
  LIGHTSPEED: {
    LIGHT_SPEED_IN: 'lightSpeedIn',
    LIGHT_SPEED_OUT: 'lightSpeedOut'
  },
  ROTATING_ENTRNACE: {
    ROTATE_IN: 'rotateIn',
    ROTATE_IN_DOWN_LEFT: 'rotateInDownLeft',
    ROTATE_IN_DOWN_RIGHT: 'rotateInDownRight',
    ROTATE_IN_UP_LEFT: 'rotateInUpLeft',
    ROTATE_IN_UP_RIGHT: 'rotateInUpRight'
  },
  ROTATING_EXIT: {

  },
  SLIDE_ENTRANCE: {
    SLIDE_IN_UP: 'slideInUp',
    SLIDE_IN_LEFT: 'slideInLeft',
    SLIDE_IN_RIGHT: 'slideInRight',
    SLIDE_IN_DOWN: 'slideInDown'
  },
  ZOOM_ENTRANCE: {
    ZOOM_IN: 'zoomIn',
    ZOOM_IN_DOWN: 'zoomInDown',
    ZOOM_IN_LEFT: 'zoomInLeft',
    ZOOM_IN_RIGHT: 'zoomInRight',
    ZOOM_IN_UP: 'zoomInUp'
  },
  ZOOM_EXITS: {
    ZOOM_OUT: 'zoomOut'
  },
  SPECIAL: {
    HINGE: 'hinge',
    JACK_IN_THE_BOX: 'jackInTheBox',
    ROLL_IN: 'rollIn',
    ROLL_OUT: 'rollOut'
  }
}

export default ANIMATIONS