import * as React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'clsx'
import { HOUR_MINUTE_OPTIONS } from '@devexpress/dx-scheduler-core'

const addCommaAndSpaceToString = string => string && `${string},\xa0`

const styles = ({ palette, spacing }) => ({
  title: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center'
  },
  textContainer: {
    lineHeight: 1,
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  middleContainer: {
    lineHeight: '1.5',
    textAlign: 'center'
  },
  time: {
    fontSize: '3vw',
    '@media (min-width: 401px)': {
      fontSize: '0.75rem'
    },
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  content: {
    color: palette.common.white,
    padding: spacing(0.5, 1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    '@media (max-width: 500px)': {
      paddingLeft: spacing(0.5),
      paddingRight: spacing(0.5)
    }
  },
  shortContent: {
    padding: spacing(0.25, 1),
    '@media (max-width: 500px)': {
      paddingLeft: spacing(0.5),
      paddingRight: spacing(0.5)
    }
  },
  shortContainer: {
    display: 'flex'
  },
  shortTime: {
    textOverflow: 'initial',
    flexShrink: 0
  },
  shortTitle: {
    flexShrink: 3
  },
  container: {
    width: '100%'
  },
  recurringContainer: {
    width: `calc(100% - ${spacing(2)}px)`
  },
  imageContainer: {
    width: spacing(2),
    height: spacing(2)
  },
  image: {
    width: '100%',
    height: '100%'
  }
})

const VerticalAppointmentBase = ({
  classes,
  data,
  children,
  className,
  formatDate,
  recurringIconComponent: RecurringIcon,
  durationType,
  ...restProps
}) => {
  const repeat = !!data.rRule
  const isShortHeight = durationType === 'short'
  const isMiddleHeight = durationType === 'middle'

  return (
    <div
      className={classNames(
        {
          [classes.content]: true,
          [classes.shortContent]: isShortHeight || isMiddleHeight
        },
        className
      )}
      {...restProps}
    >
      {children || (
        <React.Fragment>
          <div
            className={classNames({
              [classes.container]: !repeat,
              [classes.recurringContainer]: repeat
            })}
          >
            {isShortHeight ? (
              <div className={classes.shortContainer}>
                <div className={classNames(classes.title, classes.shortTitle)}>
                  {addCommaAndSpaceToString(data.teacher.firstName)}
                </div>
                <div className={classNames(classes.time, classes.shortTime)}>
                  {formatDate(data.startDate, HOUR_MINUTE_OPTIONS)}
                </div>
              </div>
            ) : (
              <React.Fragment>
                <div className={classes.title}>{data.teacher.firstName}</div>
                <div
                  className={classNames({
                    [classes.textContainer]: true,
                    [classes.middleContainer]: isMiddleHeight
                  })}
                >
                  <div className={classes.time}>
                    {formatDate(data.startDate, HOUR_MINUTE_OPTIONS)}
                  </div>
                  <div className={classes.time}>-</div>
                  <div className={classes.time}>
                    {formatDate(data.endDate, HOUR_MINUTE_OPTIONS)}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          {repeat ? (
            <div className={classes.imageContainer}>
              <RecurringIcon className={classes.image} />
            </div>
          ) : undefined}
        </React.Fragment>
      )}
    </div>
  )
}

VerticalAppointmentBase.propTypes = {
  // oneOfType is a workaround because withStyles returns react object
  recurringIconComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  formatDate: PropTypes.func.isRequired,
  durationType: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string
}

VerticalAppointmentBase.defaultProps = {
  children: undefined,
  className: undefined
}

export default withStyles(styles, { name: 'VerticalAppointment' })(
  VerticalAppointmentBase
)
