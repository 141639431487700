export const feedbackES = {
  completedOf: 'Completado',
  tryAgain: '¡Volver a intentar!',
  dashboard: 'Ir al dashboard',
  score: '¡Has conseguido un {{score}}!',
  info: {
    completed: '¡Completado!',
    pending:
      'Tus respuestas serán evaluadas previamente por un profesor, te notificaremos cuando estén listas.',
    click: 'Puedes ver el transcurso de tu evaluación haciendo click.',
    see: 'Ver evaluación'
  },
  graphics: {
    total: 'Totales',
    points: 'Acertadas'
  }
}
