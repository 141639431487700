export const agreementES = {
  CONFIRMED: {
    about:
      'Gracias! el profesor ha recibido tus deseos y está preparando tu clase',
    mail: 'Recibirás un correo recordándote la fecha de tu clase.',
    notification:
      '10 minutos antes de la clase recibirás notificación para que puedas acceder a tu aula. Todo dentro de B1B2 Top.',
    reminder:
      'Recuerda ser puntual, las clases no pueden cambiar de hora, ni ser canceladas.',
    title: 'Confirmado! Ya tienes tu clase programada'
  },
  CUSTOMIZE: {
    about: '¿De qué quieres que trate la clase?',
    confirm: 'Confirmar',
    level: '¿Qué nivel de inglés tienes?',
    title: 'Y por último... personaliza tu clase'
  },
  PICKER: {
    description:
      'Puedes escoger que idioma te gustaría que usara el profesor en tu clase.',
    isNotFirstTime: {
      description:
        'Si ya has tenido una clase con nosotros, escoge un profesor para ver su disponibilidad:',
      subheader:
        'Si todavía no has tenido ninguna clase con nosotros vuelve a la ventana anterior.',
      title: 'Selecciona tu profesor'
    },
    languagePicker: {
      _1:
        'Si escoges - Español, el profesor podrá darte la clase en español e inglés.',
      _2: 'Si escoges - Inglés, el profesor solo hablará inglés'
    },
    option: 'Escoge una opción:',
    title: '¿Cómo te gustaría que hablara tu profesor?'
  },
  PRIMARY: {
    about: `
      Con tan solo unos clicks podrás organizar una clase de inglés
      con un experto de {{model}}. Podrás elegir que el profesor hable en
      español e inglés o solo en inglés. Podrás personalizar tu clase
      escogiendo la temática o competencia del examen que quieres que
      el profesor se centre. Tan solo necesitaras tener una hora
      disponible y una conexión de internet estable. No tendrás que
      descargarte nada, todo aquí desde casa en B1B2 Top.`,
    confirm: 'Confirma una clase particular',
    foryou: 'solo para ti',
    customize: 'Personaliza',
    class: 'tu clase',
    few: 'En tan solo',
    clicks: 'unos clicks',
    prepare: 'Prepara competencias',
    specific: 'específicas del examen',
    title: 'Conviertete en un master de {{model}}'
  },
  back: 'Anterior',
  isNotMyFirstTime: 'No es mi primera vez',
  next: 'Siguiente',
  title: '¡Puedes escoger el horario que más te guste!',
  reserved: 'Reservado',
  reserve: 'Reservar',
  anticipationWarning: 'Mínimo 4 horas de antelación para poder reservar tu clase',
  subscribe: "Elige el paquete de clases que mejor se adapte a ti",
  purchase: '¡Felicidades, ahora puedes reservar tu clase!'
}
