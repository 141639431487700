export const examsEN = {
  feedback: {
    correct: 'Your answer is correct!',
    incorrect: 'Your answer is incorrect!'
  },
  info: 'Access to all the material (more than 50 hours of practice)!',
  rows: {
    checking: 'Graded by natives teachers',
    price: 'Price',
    type: 'Type'
  },
  subscription: 'In order to see your results, subscribe!'
}
