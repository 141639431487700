import styled from 'styled-components'

export const ContentContainer = styled.div`
  margin-bottom: .3rem !important;
  padding-bottom: .3rem!important;
`

export const SelectionContainer = styled.span`
  padding: 1.5px 1.5px 2px 2px;
`
export const Circle = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  background: #00ccc6;
  margin-bottom: 20px;
  color: #FFFFFF;
`

export const AnswerContainer = styled.div`
  margin-bottom: 5px;
  margin-top: 2.5px;
  padding-top: 2.5px;
  padding-bottom: 5px;
`

export const TextSmall = styled.span`
  font-size: 12px !important;
`

export const EmojiContainer = styled.div`
  margin-right: 12px;
`

export const TextInput = styled.small`
  font-size: 11px !important;
`