export const paymentsEN = {
  selectPackage: 'Select a package',
  courses: 'Courses',
  exams: 'Exams',
  info: {
    courses: {
      access: 'Access the course'
    },
    exams: {
      access: 'Full access'
    }
  },
  month: 'month',
  package: {
    buy: 'Buy package',
    review: 'Test review',
    speakings: {
      notAvailable: 'It does not include speakings'
    },
    withoutReview: 'Without revision',
    writings: {
      notAvailable: 'It does not include writings'
    }
  },
  packages: 'AptisGo package'
}
