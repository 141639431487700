export const examsES = {
  feedback: {
    correct: '¡Tu respuesta es correcta!',
    incorrect: '¡Tu respuesta es incorrecta!'
  },
  info: '¡Acceso a todo el material (más de 50 horas de práctica)!',
  rows: {
    checking: 'Corregidos por profesor nativo',
    price: 'Precio',
    type: 'Tipo'
  },
  subscription: 'Para poder ver tus resultados, Subscríbete!'
}
