export const CONNECT_USER = 'participantConnected'

export const DISCONNECT_USER = 'participantDisconnected'

export const TRACK_SUBSCRIBED = 'trackSubscribed'

export const TRACK_UNSUBSCRIBED = 'trackUnsubscribed'

export const TRACK_PUBLISHED = 'trackPublished'

export const TRACK_UNPUBLISHED = 'trackUnpublished'

export const DOMINANT_SPEAKER_CHANGED = 'dominantSpeakerChanged'

export const TRACK_ADDED = 'TRACK_ADDED'

export const TRACK_ENABLED = 'trackEnabled'

export const TRACK_DISABLED = 'trackDisabled'