const PATH = Object.freeze({
  AGREEMENT: '/account/agreement',
  CLASSES: '/account/classes',
  COURSES: '/courses',
  CREATE_MEETING: '/admin/meetings',
  DASHBOARD: '/account/dashboard',
  DEMO: '/demo',
  ERROR: '/account/error',
  EVALUATION: '/evaluations',
  EXAMS: '/exams',
  EXCHANGE: '/accounts/exchange',
  EXERCISES: '/exercises',
  FEEDBACK: '/feedback',
  FORGOT: '/accounts/forgot',
  GIFT: '/account/gift',
  LOGIN: '/accounts/login',
  LOGOUT: '/logout',
  MEETINGS: '/meetings',
  MODELS: '/models',
  PASSWORD: '/account/dashboard/settings/password',
  PAYMENTS: '/account/pricing',
  QUALITY: '/quality',
  REQUIRED: '/required',
  RESET: '/accounts/reset',
  RESULTS: '/results',
  SECTIONS: '/sections',
  SETTINGS: '/account/dashboard/settings',
  SIGN_UP: '/accounts/signup',
  STREAMING: '/streaming',
  TESTING: '/testing',
  VERIFICATION: '/account/verification',
  LATEST: '/account/evaluations',
  NOTIFICATIONS: '/account/notifications',
  STATS: '/account/stats'
})

export default PATH
