/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ToastsStore } from 'react-toasts'
import { useTranslation } from 'react-i18next'
import { unwrapResult } from '@reduxjs/toolkit'
import { Checkbox } from '@material-ui/core'


import { Button } from 'styled'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'

import GoogleAuthenticator from 'components/GoogleAuthenticator'
import Template from 'components/Template'
import Text from 'components/Text'
import FlexContainer from 'components/FlexContainer'
import ModalDialog from 'components/ModalDialog'
import useToggler from 'hooks/useToggler'
import TermsAndCondition from './components/TermsAndCondition'


import useAuthProvider from 'hooks/useAuthProvider'
import useForm from 'hooks/useForm'
import useLocalStorage from 'hooks/useLocalStorage'
import useQuery from 'hooks/useQuery'

import PATH from 'utils/path'

import links from './config/ref'
import like from 'modules/words'
import { demoThunk, signUpThunk, socialThunk } from 'store/@thunks/auth'

import { BLUE, WHITE } from 'assets/colors'

import { selectModel } from 'store/@reducers/models'
import { fetchModelsThunk } from 'store/@thunks/models'

import styles from './login.module.scss'
import {
  loginGoogleValidator,
  loginNameValidator,
  loginPasswordValidator
} from './validation/login'


const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      display: 'flex',
      flexDirection: 'column',
      color: 'white'
    }
  }
}))

const SignUp = () => {
  const classes = useStyles()

  const user = useAuthProvider()

  const dispatch = useDispatch()

  const history = useHistory()

  const ls = useLocalStorage()

  const [form, handleChange] = useForm(initialFormValues)

  const { redirect } = useQuery()

  const { t } = useTranslation()

  const [terms, switchTerms] = useToggler()

  const [accept, setAccept] = useToggler()

  /**
   * @description
   * Checking when the user isLoggedIn
   */
  useEffect(() => {
    if (user.isLoggedIn) {
      const { token } = user.profile

      ToastsStore.info(t('AUTHENTICATION.welcome'))

      const withStringify = false

      ls.setItem(token, withStringify)

      /**
       * @description
       * Redirect query can be used to push to dashboard without anything.
       */
      if (redirect) {
        const path = links.find(link => {
          return like([redirect], link.ref)
        })

        return path ? history.push(path.route) : history.push(PATH.MODELS)
      } else {
        history.push(PATH.MODELS)
      }

      /**
       * User has already a model selected? we need to fetch the models
       */
      if (user.profile.model) {
        dispatch(fetchModelsThunk())

        dispatch(selectModel(user.profile.model))

        history.push(PATH.DASHBOARD)
      } else {
        history.push(PATH.MODELS)
      }
    }
  }, [user.isLoggedIn, redirect, t])

  /**
   * @description
   * Social login thunk handler
   */
  const handleSocialProviderAuthentication = useCallback(
    (provider, profile) => {
      switch (provider) {
        case 'Google':
          dispatch(socialThunk({ user: profile, provider: 'google' }))
            .then(unwrapResult)
            .catch(({ message }) => ToastsStore.warning(message))
          return

        case 'Facebook':
          dispatch(socialThunk({ user: profile, provider: 'facebook' }))
            .then(unwrapResult)
            .catch(({ message }) => ToastsStore.warning(message))
          return

        default:
          return
      }
    },
    [dispatch]
  )

  const handleSocialLoginError = () => {
    ToastsStore.error(t('AUTHENTICATION.error'))
  }

  const handleSignUp = () => {
    const validations = [
      loginGoogleValidator,
      loginNameValidator,
      loginPasswordValidator
    ]

    const validation = validations.findIndex(intercept => {
      return intercept(form).validation
    })

    if (validation !== -1) {
      return ToastsStore.info(validations[validation](form).message)
    }

    if (accept) {
      return dispatch(
        signUpThunk({
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
          password: form.password
        })
      )
        .then(unwrapResult)
        .catch(({ message }) => ToastsStore.warning(message))
    }

    ToastsStore.warning(
      t('AUTHENTICATION.mustAcceptTerms')
    )
  }


  const handleDemoLogin = () => {
    dispatch(demoThunk())
  }

  return (
    <Template withNavbar={false} withLoader={user.loading}>
      <div className={styles.container}>
        <div className={styles.shadow}>
          <div className={styles.access}>
            <Text color="white" tag="h2">
              B1B2 ONLINE
            </Text>
            <form className={classes.root} noValidate autoComplete="off">
              <>
                <TextField
                  autoComplete="false"
                  color="secondary"
                  label={t('AUTHENTICATION.firstName')}
                  name="firstName"
                  onChange={handleChange}
                  type="text"
                  variant="filled"
                  value={form.firstName}
                />
                <TextField
                  autoComplete="false"
                  color="secondary"
                  label={t('AUTHENTICATION.lastName')}
                  name="lastName"
                  onChange={handleChange}
                  type="text"
                  variant="filled"
                  value={form.lastName}
                />
                <TextField
                  autoComplete="false"
                  color="secondary"
                  label={t('AUTHENTICATION.email')}
                  name="email"
                  onChange={handleChange}
                  type="email"
                  variant="filled"
                  value={form.email}
                />
                <TextField
                  autoComplete="false"
                  color="secondary"
                  label={t('AUTHENTICATION.password')}
                  name="password"
                  onChange={handleChange}
                  type="password"
                  variant="filled"
                  value={form.password}
                />
                <TextField
                  autoComplete="false"
                  color="secondary"
                  label={t('AUTHENTICATION.confirm')}
                  name="confirm"
                  onChange={handleChange}
                  type="password"
                  variant="filled"
                  value={form.confirm}
                />
              </>
            </form>
            <FlexContainer className="align-items-center">
              <Checkbox checked={accept} onChange={setAccept} />
              <Text lighter tag="small" color="white" hovered onClick={switchTerms}>  
                {t('AUTHENTICATION.accept')}
              </Text>
            </FlexContainer>
            <Button background={BLUE} color={WHITE} onClick={handleSignUp}>
              {t('AUTHENTICATION.register')}
            </Button>
            <div />
          </div>
          <div className={styles.quick}>
            <Text color="blue" tag="h2">
              {t('AUTHENTICATION.quick')}
            </Text>
            <GoogleAuthenticator
              className={styles.google}
              onSuccess={({ profileObj }) =>
                handleSocialProviderAuthentication('Google', profileObj)
              }
              onFailure={handleSocialLoginError}
            />
            <Button background={BLUE} color={WHITE} onClick={handleDemoLogin}>
              {t('AUTHENTICATION.demo')}
            </Button>
          </div>
        </div>
      </div>
      <ModalDialog
        textHeader={t('AUTHENTICATION.terms')}
        size="xl"
        enabled={terms}
        onCloseRequest={switchTerms}
      >
        <TermsAndCondition />
      </ModalDialog>
    </Template>
  )
}

const initialFormValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: ''
}

export default SignUp
