export const evaluationsES = {
  seeAll: 'Ver todas',
  accept: 'Aceptar',
  back: 'Anterior',
  cancel: 'Cancelar',
  continue: 'Continue',
  score: '¡Has conseguido un {{suffix}} {{score}} en {{model}}!',
  STATUS: {
    EVALUATED: 'Evaluado',
    TAKEN: 'Pendiente'
  }
}