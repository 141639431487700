export const evaluationsEN = {
  seeAll: 'See all',
  accept: 'Accept',
  back: 'Back',
  cancel: 'Cancel',
  continue: 'Continue',
  score: '¡You have scored {{suffix}} {{score}} in {{model}}!',
  STATUS: {
    EVALUATED: 'Evaluated',
    TAKEN: 'On revision'
  }
}
