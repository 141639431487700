import React from 'react'
import { Col, Row, FormControl, Card, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ToastsStore } from 'react-toasts'
import { useTranslation } from 'react-i18next'
import { ModuleValidaton } from 'common/module.validation'
import { androidPerson } from 'react-icons-kit/ionicons/androidPerson'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import { ic_lock } from 'react-icons-kit/md/ic_lock'
import classNames from 'clsx'

import Icon from 'react-icons-kit'
import 'assets/css/input.css'
import 'assets/css/settings.css'

import Template from 'components/Template'
import Text from 'components/Text'

import useAuthProvider from 'hooks/useAuthProvider'
import useForm from 'hooks/useForm'
import useToggler from 'hooks/useToggler'
import useLocalStorage from 'hooks/useLocalStorage'

import { Button } from 'styled'

import { withVerification as WV } from 'hocs'

import { validateName } from './validation'
import { loginNameValidator, loginPasswordValidator } from '../authentication/validation/login'
import { updateProfileThunk } from 'store/@thunks/auth'
import { BLUE } from 'assets/colors'

import styles from './settings.module.scss'

const inlineStyle = {
  backgroundColor: 'transparent',
  border: 'none'
}

const Settings = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const ls = useLocalStorage()

  const { profile, demo, loading } = useAuthProvider()

  const [form, onChange] = useForm({
    email: profile.email,
    firstName: profile.firstName,
    lastName: profile.lastName,
    password: '',
    confirm: ''
  })

  const [isEdit, setIsEdit] = useToggler()

  /**
   * @description
   * Handles the promise from profile update.
   */
  const handleUpdate = () => {
    const { token } = profile

    ToastsStore.success(t('SETTINGS.update'))

    ls.setItem(token)

    setIsEdit(false)
  }

  /**
   * @description
   * Submit information, and confirm.
   */
  const onSubmit = () => {
    const validations = [loginNameValidator]

    const formValidation = validations.findIndex(validation =>
      ModuleValidaton.apply(validation, form)
    )

    if (ModuleValidaton.isValid(formValidation)) {
      return dispatch(updateProfileThunk({
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName
      }))
        .catch(() => ToastsStore.error(t('SETTINGS.error')))
        .then(handleUpdate)
    }

    return ToastsStore.info(validations[formValidation](form).message)
  }


  const onSubmitPassword = () => {
    const validations = [loginPasswordValidator]

    const formValidation = validations.findIndex(validation =>
      ModuleValidaton.apply(validation, form)
    )

    if (ModuleValidaton.isValid(formValidation)) {
      return dispatch(updateProfileThunk({
        firstName: form.firstName,
        lastName: form.lastName,
        password: form.password
      }))
        .catch(() => ToastsStore.error(t('SETTINGS.error')))
        .then(handleUpdate)
    }

    return ToastsStore.info(validations[formValidation](form).message)
  }

  return (
    <Template withSidebar withAnimationType="fadeIn" withLoader={loading} view>
      <Row>
        <Col className={styles.col}>
          <Card className={styles.card}>
            <Card.Header style={inlineStyle}>
              <Text center dunkin tag="h4" className="m-0 pt-2">
                {t('SETTINGS.title')}{' '}
                <Icon
                  className="icon"
                  icon={androidPerson}
                  size={30}
                  onClick={setIsEdit}
                />
              </Text>
            </Card.Header>

            <Card.Body className={styles.container}>
              <Form>
                {isEdit ? (
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column sm={3}>
                      {t('SETTINGS.name')}
                    </Form.Label>
                    <Col sm={9}>
                      <FormControl
                        disabled={demo}
                        required
                        name="firstName"
                        onChange={onChange}
                        type="text"
                        value={form.firstName}
                        isValid={validateName(form.firstName)}
                        isInvalid={!validateName(form.firstName)}
                      />
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column sm={3}>
                      {t('SETTINGS.name')}
                    </Form.Label>
                    <Col sm={9}>
                      <FormControl
                        disabled
                        required
                        name="firstName"
                        onChange={onChange}
                        type="text"
                        value={profile.firstName}
                      />
                    </Col>
                  </Form.Group>
                )}

                {isEdit ? (
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column sm={3}>
                      {t('SETTINGS.lastName')}
                    </Form.Label>
                    <Col sm={9}>
                      <FormControl
                        disabled={demo}
                        required
                        name="lastName"
                        onChange={onChange}
                        type="text"
                        value={form.lastName}
                        isValid={validateName(form.lastName)}
                        isInvalid={!validateName(form.lastName)}
                      />
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column sm={3}>
                      {t('SETTINGS.lastName')}
                    </Form.Label>
                    <Col sm={9}>
                      <FormControl
                        disabled
                        required
                        name="lastName"
                        onChange={onChange}
                        type="text"
                        value={profile.lastName}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Form>

              {isEdit ? (
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column sm={3}>
                    {t('SETTINGS.email')}
                  </Form.Label>
                  <Col sm={9}>
                    <FormControl
                      disabled
                      required
                      name="email"
                      onChange={onChange}
                      type="email"
                      value={demo ? 'aptisgo@demo.user' : form.email}
                    />
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column sm={3}>
                    {t('SETTINGS.email')}
                  </Form.Label>
                  <Col sm={9}>
                    <FormControl
                      required
                      disabled
                      name="email"
                      onChange={onChange}
                      type="email"
                      value={demo ? 'aptisgo@demo.user' : form.email}
                    />
                  </Col>
                </Form.Group>
              )}
              {isEdit ? (
                <div className={styles.flex}>
                  <br />
                  <Button
                    background={BLUE}
                    disabled={demo}
                    onClick={onSubmit}
                    className="mr-3 px-4"
                  >
                    {t('SETTINGS.save')}
                  </Button>
                  <Button
                    background={BLUE}
                    onClick={setIsEdit}
                    className="px-4"
                  >
                    {t('SETTINGS.cancel')}
                  </Button>
                </div>
              ) : (
                <div className={classNames(styles.margin, 'text-center')}>
                  <Button
                    background={BLUE}
                    disabled={demo}
                    onClick={setIsEdit}
                    className="px-4"
                  >
                    {t('SETTINGS.edit')}
                    <Icon className="icon ml-2" icon={ic_mode_edit} />
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col className={styles.col}>
          <Card className={styles.card}>
            <Card.Header style={inlineStyle}>
              <Text center dunkin tag="h4" className="m-0 pt-2">
                {t('AUTHENTICATION.password').replace(':', '')}
                <Icon
                  className="icon ml-2"
                  icon={ic_lock}
                  size={30}
                  onClick={setIsEdit}
                />
              </Text>
            </Card.Header>
            <Card.Body className={classNames(styles.content, styles.container)}>
              <Form.Group as={Row} className="align-items-center">
                <Form.Label column sm={3}>
                  {t('AUTHENTICATION.password')}
                </Form.Label>
                <Col sm={9}>
                  <FormControl
                    required
                    disabled={demo}
                    name="password"
                    onChange={onChange}
                    type="password"
                    value={demo ? 'aptisgo@demo.user' : form.password}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center">
                <Form.Label column sm={3}>
                  {t('SETTINGS.confirm')}
                </Form.Label>
                <Col sm={9}>
                  <FormControl
                    required
                    disabled={demo}
                    name="confirm"
                    onChange={onChange}
                    type="password"
                    value={demo ? 'aptisgo@demo.user' : form.confirm}
                  />
                </Col>
              </Form.Group>
              <div className={classNames('text-center', styles.margin)}>
                <Button
                  background={BLUE}
                  disabled={demo}
                  onClick={onSubmitPassword}
                  className="px-4"
                >
                  {t('SETTINGS.password')}
                  <Icon
                    className="icon ml-2"
                    size={20}
                    icon={ic_lock}
                    onClick={setIsEdit}
                  />
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Template>
  )
}

export default WV(Settings)
