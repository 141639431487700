export const dashboardEN = {
  welcome: 'Welcome {{user}}!',
  classes: {
    online: 'Online',
    classes: 'CLASSES',
    individuals: 'One to One classes',
    experts: 'Expert teachers',
    consult: 'Timetable',
    reserve: 'Book a class'
  },
  stats: {
    title: 'Statistics',
    see: 'See all',
    exam: 'T'
  },
  evaluations: {
    category: 'Category',
    date: 'Date',
    options: 'Options',
    status: 'Status'
  },
  notification: `
  The ten tests have been uptaded to the official changes
  Aptis 2020. We are the first to update it anywhere!`,
  users: {
    latest: {
      header: 'Latest changes',
      subheader: 'Your previous progress'
    },
    evaluations: {
      header: 'Evaluation',
      management: {
        evaluated: 'Evaluated'
      },
      messages: {
        notAvailable: 'No results available'
      },
      subheader: 'Your current progress'
    },
    exams: {
      header: 'Tests',
      messages: {
        notAvailable: 'There are no exams available'
      },
      subheader: 'Start to practice right now!',
      textName: 'Test'
    },
    material: {
      main: 'Study materials',
      sub: 'Aptis Exams'
    },
    rows: {
      category: 'Category',
      evaluation: 'Evaluated',
      options: 'Options',
      order: '#',
      state: 'State'
    },
    stats: {
      main: 'statistics',
      secondary: 'By category'
    }
  }
}
