export const plansEN = {
  title: 'Packages',
  package: 'Package',
  price: 'Price',
  exams: 'Exams',
  classes: 'Classes',
  course: 'Course',
  evaluations: 'Evaluations',
  speakings: 'Speakings',
  writings: 'Writings',
  PRICING: {
    particulars: '{{classes}} private lessons',
    particular: '{{classes}} private lesson',
    exams: 'Complete exams',
    course: 'Online course',
    automatic: 'Automatic corrections',
    pendings: 'Corrections by teachers',
    withoutClass: 'No classes',
    writings: '{{writing}} writings',
    speakings: '{{speaking}} speakings',
    complete: '{{writing}} writings y {{speaking}} speakings'
  },
  month: 'Month',
  purchase: 'Purchase',
  banner: {
    Blue: '1 Private lesson {v} Choose your schedule',
    Diamond:
      '10 Full exams +{v} 1 Course +{v} 1 Private lesson',
    'Curso Aptis': 'Complete Aptis course {v} Tips on all skills',
    Green: 'Private classes {v} Preferential schedule',
    'Grand Master':
      'Full exams {v} Corrections for all skills {v} Corrections by tutors {v} Private lessons {v} Full Aptis course {v} Full Aptis course',
    Go:
      '10 Full exams {v} With instant feedback',
    Master:  
      '10 Full exams {v} 10 Speaking + 10 Writing {v} 4 Private lessons {v} 1 Aptis Course',
    Silver:
      '10 Full exams +{v} 5 Writings +{v} Corrected by a tutor',
    Gold:
      '10 Full exams +{v} 5 Speakings +{v} Corrected by a tutor',
    Platinum:
      '10 Full exams {v} 5 Writings + 5 Speakings{v} Corrected by a tutor',

  }
}
