export const plansES = {
  title: 'Paquetes',
  package: 'Paquete',
  price: 'Precio',
  exams: 'Examenes',
  classes: 'Clases',
  course: 'Curso',
  evaluations: 'Evaluaciones',
  speakings: 'Speakings',
  writings: 'Writings',
  PRICING: {
    particulars: '{{classes}} Clases particulares',
    particular: '{{classes}} Clase particular',
    exams: 'Exámenes completos',
    course: 'Curso online',
    automatic: 'Correcciones automáticas',
    pendings: 'Correcciones por profesores',
    withoutClass: 'Sin clases',
    writings: '{{writings}} Writings',
    speakings: '{{speakings}} Speakings',
    complete: '{{writings}} Writings & {{speakings}} Speakings'
  },
  month: 'Mes',
  purchase: 'Comprar',
  banner: {
    Blue: '1 Clase particular {v} Elige tu horario',
    Diamond:
      '10 Exámenes + {v} 1 Curso completo +{v} 1 Clase particular ',
    'Curso Aptis': 'Curso completo de Aptis {v} Todo el temario',
    Green: 'Clases particulares {v} Horario preferencial',
    'Grand Master':
      'Exámenes completos {v} Correcciones de todas las destrezas {v} Correcciones por tutores {v} Clases particulares {v} Curso completo de Aptis',
    Go:
      '10 exámenes completos {v} Con autocorrecciones',
    Master:
      '10 exámenes completos {v} 10 Speaking + 10 Writing {v} 4 clases particulares {v} 1 Curso de Aptis',
    Silver:
      '10 exámenes completos {v} 5 Writings {v} Corregidos por un profesor',
      Gold:
      '10 exámenes completos {v} 5 Speakings {v} Corregidos por un profesor',
    Platinum:
      '10 exámenes completos {v} 5 Writings + 5 Speakings{v} Corregidos por un profesor',

  }
}
