export const svg = {
  "1": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/1.svg",
  "2": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/2.svg",
  "3": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/3.svg",
  "4": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/4.svg",
  "5": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/5.svg",
  "6": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/6.svg",
  "7": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/7.svg",
  "8": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/8.svg",
  "9": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/9.svg",
  "10": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/10.svg",
  "aptis": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/aptis.svg",
  "background": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/background.svg",
  "dialog": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/dialog.svg",
  "blue": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/blue.svg",
  "brand": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/brand.svg",
  "completed": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/completed.svg",
  "courses": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/courses.svg",
  "diamond": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/diamond.svg",
  "go": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/go.svg",
  "gold": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/gold.svg",
  "grandmaster": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/grandmaster.svg",
  "green": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/green.svg",
  "index": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/index.js",
  "logo": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/logo.svg",
  "master": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/master.svg",
  "materials": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/materials.svg",
  "model": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/model.svg",
  "platinum": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/platinum.svg",
  "ruby": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/ruby.svg",
  "silver": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/silver.svg",
  "josh": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/Josh.svg",
  "waiting": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/waiting.svg",
  "leaft": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/leaft.svg",
  "sidebar": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/sidebar.svg",
  "utilities": "https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/utilities.svg"
}