/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { ToastsStore } from 'react-toasts'
import { useTranslation } from 'react-i18next'
import { unwrapResult } from '@reduxjs/toolkit'

import { Button } from 'styled'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

import GoogleAuthenticator from 'components/GoogleAuthenticator'
import Template from 'components/Template'
import Text from 'components/Text'

import useAuthProvider from 'hooks/useAuthProvider'
import useForm from 'hooks/useForm'
import useLocalStorage from 'hooks/useLocalStorage'
import useQuery from 'hooks/useQuery'
import useToggler from 'hooks/useToggler'


import PATH from 'utils/path'
import Panda from 'assets/img/panda.svg'

import links from './config/ref'
import like from 'modules/words'
import {
  demoThunk,
  forgotPasswordThunk,
  loginThunk,
  socialThunk
} from 'store/@thunks/auth'

import { BLUE, WHITE } from 'assets/colors'

import { selectModel } from 'store/@reducers/models'
import { fetchModelsThunk } from 'store/@thunks/models'

import styles from './login.module.scss'
import { createNavigationPath } from 'modules/url'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      display: 'flex',
      flexDirection: 'column',
      color: 'white'
    }
  }
}))

const Login = () => {
  const classes = useStyles()

  const user = useAuthProvider()

  const dispatch = useDispatch()

  const history = useHistory()

  const ls = useLocalStorage()

  const [form, handleChange, reset] = useForm(initialFormValues)

  const { redirect, model } = useQuery()

  const { t } = useTranslation()

  const [forgot, setForgot] = useToggler()

  /**
   * @description
   * Checking when the user isLoggedIn
   */
  useEffect(() => {
    if (user.isLoggedIn) {
      const { token } = user.profile

      if (user.profile.email.includes('aptisgo@noreply') === false) {
        ToastsStore.info(t('AUTHENTICATION.welcome'))
      }

      const withStringify = false

      ls.setItem(token, withStringify)

      /**
       * @description
       * Redirect query can be used to push to dashboard without anything.
       */
      if (redirect) {
        const path = links.find(link => {
          return like([redirect], link.ref)
        })

        if (model) {
          history.push(createNavigationPath(PATH.MODELS, {
            query: model
          }))
        } else {
          path ? history.push(path.route) : history.push(PATH.MODELS)
        }
      }

      /**
       * User has already a model selected? we need to fetch the models
       */
      if (user.profile.model) {
        dispatch(fetchModelsThunk())

        dispatch(selectModel(user.profile.model))

        history.push(PATH.DASHBOARD)
      } else {
        user.demo || history.push(PATH.MODELS)
      }
    }
  }, [user.isLoggedIn, redirect, t])


  const handleAuthenticationLocal = e => {
    e.preventDefault()

    dispatch(loginThunk(form))
      .then(unwrapResult)
      .catch(({ message }) => ToastsStore.warning(message))
  }

  /**
   * @description
   * Sending mail to the server for recovering password
   */
  const handleRecoverPassword = async () => {
    dispatch(
      forgotPasswordThunk({
        email: form.recover
      })
    )
      .then(unwrapResult)
      .then(() => {
        ToastsStore.success(t('FORGOT.success', { email: form.recover }))

        reset()
      })
      .catch(({ message }) => {
        ToastsStore.warning(message)
      })
  }

  /**
   * @description
   * Social login thunk handler
   */
  const handleSocialProviderAuthentication = useCallback(
    (provider, profile) => {
      switch (provider) {
        case 'Google':
          dispatch(socialThunk({ user: profile, provider: 'google' }))
            .then(unwrapResult)
            .catch(({ message }) => ToastsStore.warning(message))
          return

        case 'Facebook':
          dispatch(socialThunk({ user: profile, provider: 'facebook' }))
            .then(unwrapResult)
            .catch(({ message }) => ToastsStore.warning(message))
          return

        default:
          return
      }
    },
    [dispatch]
  )

  const handleSocialLoginError = () => {
    ToastsStore.error(t('AUTHENTICATION.error'))
  }

  const handleDemoSession = () => {
    dispatch(demoThunk())
      .then(unwrapResult)
      .then(() => history.push(PATH.MODELS))
      .catch(({ message }) => ToastsStore.warning(message))
  }

  return (
    <Template withNavbar={false} withLoader={user.loading}>
      <div className={styles.container}>
        <div className={styles.shadow}>
          <div className={styles.access}>
            <Text color="white" tag="h2">
              B1B2 ONLINE
            </Text>
            {forgot && (
              <>
                <Text color="white" tag="p">
                  {t('FORGOT.main')}
                </Text>
              </>
            )}
            <form className={classes.root} noValidate autoComplete="off">
              {forgot ? (
                <TextField
                  autoComplete="false"
                  color="secondary"
                  label={t('AUTHENTICATION.email')}
                  placeholder={t('AUTHENTICATION.email')}
                  name="recover"
                  onChange={handleChange}
                  type="email"
                  variant="filled"
                  value={form.recover}
                />
              ) : (
                <>
                  <TextField
                    autoComplete="false"
                    color="secondary"
                    label={t('AUTHENTICATION.email')}
                    name="email"
                    onChange={handleChange}
                    type="email"
                    variant="filled"
                    value={form.email}
                  />
                  <TextField
                    autoComplete="false"
                    color="secondary"
                    label={t('AUTHENTICATION.password')}
                    name="password"
                    onChange={handleChange}
                    type="password"
                    variant="filled"
                    value={form.password}
                  />
                  <div className="text-right">
                    <Link className={styles.link} onClick={setForgot}>
                      {t('AUTHENTICATION.forgot')}
                    </Link>
                  </div>
                </>
              )}
            </form>
            {forgot ? (
              <div>
                <Button
                  background={WHITE}
                  color={BLUE}
                  onClick={handleRecoverPassword}
                >
                  {t('FORGOT.send')}
                </Button>
                <div className={styles.margin}>
                  <Link className={styles.signup} to={PATH.SIGN_UP}>
                    {t('AUTHENTICATION.sign')}
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <Button
                  background={WHITE}
                  color={BLUE}
                  onClick={handleAuthenticationLocal}
                >
                  {t('AUTHENTICATION.login')}
                </Button>
                <div />
                <div className="d-block d-sm-none">
                  <GoogleAuthenticator
                    className={styles.google}
                    onSuccess={({ profileObj }) =>
                      handleSocialProviderAuthentication('Google', profileObj)
                    }
                    onFailure={handleSocialLoginError}
                  />
                  <Button
                    background={BLUE}
                    color={WHITE}
                    onClick={handleDemoSession}
                  >
                    {t('AUTHENTICATION.demo')}
                  </Button>
                </div>
                <div className={styles.margin}>
                  <Link to={PATH.SIGN_UP} className={styles.signup}>
                    {t('AUTHENTICATION.sign')}
                  </Link>
                </div>
              </div>
            )}
            <div />
          </div>
          <div className={styles.quick}>
            <Text color="blue" tag="h2" onClick={handleDemoSession}>
              {t('AUTHENTICATION.quick')}
            </Text>
            <GoogleAuthenticator
              className={styles.google}
              onSuccess={({ profileObj }) =>
                handleSocialProviderAuthentication('Google', profileObj)
              }
              onFailure={handleSocialLoginError}
            />
            <Button background={BLUE} color={WHITE} onClick={handleDemoSession}>
              {t('AUTHENTICATION.demo')}
            </Button>
            {forgot || (
              <img
                about="panda"
                src={Panda}
                alt="panda"
                className="img-fluid"
              />
            )}
          </div>
        </div>
      </div>
    </Template>
  )
}

const initialFormValues = {
  email: '',
  password: '',
  recover: ''
}

export default Login
