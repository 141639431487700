import React from 'react'
import { Button } from 'react-bootstrap'
import { check } from 'react-icons-kit/fa/check'
import { times } from 'react-icons-kit/fa/times'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import useSVG from 'hooks/useSVG'
import useToggler from 'hooks/useToggler'

import Icon from 'react-icons-kit'

import Text from 'components/Text'

import plans from 'utils/plans'
import { getPackagePrice } from 'utils/functions'

import styles from './cards.module.scss'
import './index.scss'

import BlueLeft from 'assets/svg/blue-left.svg'
import BlueRight from 'assets/svg/blue-right.svg'
import RedLeft from 'assets/svg/red-left.svg'
import RedRight from 'assets/svg/red-right.svg'
import TurquoiseLeft from 'assets/svg/turquoise-left.svg'
import TurquoiseRight from 'assets/svg/turquoise-right.svg'
import Payment from 'components/Payment'
import { select } from 'store/@reducers/plans'
import { ToastsStore } from 'react-toasts'
import PATH from 'utils/path'

/**
 * @typedef {Object} CardsState
 * @property {string} name
 * @property {string} className
 */

/**
 * @type {React.FunctionComponent<CardsState>}
 */
const Card = ({
  currency,
  classes,
  children,
  className,
  name,
  price,
  index,
  speaking,
  writing,
  ...props
}) => {

  const history = useHistory()

  const dispatch = useDispatch()

  const [checkout, setCheckout] = useToggler()

  const { t } = useTranslation()

  const svg = useSVG({
    attributes: [['width', '70px']]
  })

  /**
   * @param {string} text
   * @param {boolean} include
   */
  const getPricingDetail = (text, include) => {
    return (
      <Text tag="li">
        <Icon
          className={`mr-2 ${include ? 'text-success' : 'text-danger'}`}
          icon={include ? check : times}
        />
        {include ? text : <del>{text}</del>}
      </Text>
    )
  }

  const getClassesDetails = () => {
    return (
      <React.Fragment>
        {classes ? (
          <>
            {classes > 1
              ? getPricingDetail(
                  t('PLANS.PRICING.particulars', { classes }),
                  true
                )
              : getPricingDetail(
                  t('PLANS.PRICING.particular', { classes }),
                  true
                )}
          </>
        ) : (
          <>{getPricingDetail(t('PLANS.PRICING.withoutClass'), false)}</>
        )}
      </React.Fragment>
    )
  }
  function description() {
    switch (name) {
      case plans.GO:
        return (
          <>
            {getPricingDetail(t('PLANS.PRICING.exams'), true)}
            {getPricingDetail(t('PLANS.PRICING.course'), false)}
            {getPricingDetail(t('PLANS.PRICING.automatic'), true)}
            {getPricingDetail(t('PLANS.PRICING.pendings'), false)}
            {getPricingDetail(t('PLANS.PRICING.complete'), false)}
            {getClassesDetails()}
          </>
        )
      case plans.SILVER:
        return (
          <>
            {getPricingDetail(t('PLANS.PRICING.exams'), true)}
            {getPricingDetail(t('PLANS.PRICING.course'), false)}
            {getPricingDetail(t('PLANS.PRICING.automatic'), true)}
            {getPricingDetail(t('PLANS.PRICING.pendings'), true)}
            {getPricingDetail(
              t('PLANS.PRICING.writings', { writings: writing }),
              true
            )}
            {getClassesDetails()}
          </>
        )
      case plans.GOLD:
        return (
          <>
            {getPricingDetail(t('PLANS.PRICING.exams'), true)}
            {getPricingDetail(t('PLANS.PRICING.course'), false)}
            {getPricingDetail(t('PLANS.PRICING.automatic'), true)}
            {getPricingDetail(t('PLANS.PRICING.pendings'), true)}
            {getPricingDetail(
              t('PLANS.PRICING.speakings', {
                speakings: speaking
              }),
              true
            )}
            {getClassesDetails()}
          </>
        )
      case plans.PLATINUM:
        return (
          <>
            {getPricingDetail(t('PLANS.PRICING.exams'), true)}
            {getPricingDetail(t('PLANS.PRICING.course'), false)}
            {getPricingDetail(t('PLANS.PRICING.automatic'), true)}
            {getPricingDetail(t('PLANS.PRICING.pendings'), true)}
            {getPricingDetail(
              t('PLANS.PRICING.complete', {
                writings: writing,
                speakings: speaking
              }),
              true
            )}
            {getClassesDetails()}
          </>
        )
      case plans.APTIS:
        return (
          <>
            {getPricingDetail(t('PLANS.PRICING.exams'), false)}
            {getPricingDetail(t('PLANS.PRICING.course'), true)}
            {getPricingDetail(t('PLANS.PRICING.automatic'), true)}
            {getPricingDetail(t('PLANS.PRICING.pendings'), false)}
            {getPricingDetail(
              t('PLANS.PRICING.complete', {
                writings: writing,
                speakings: speaking
              }),
              false
            )}
            {getClassesDetails()}
          </>
        )
      case plans.DIAMOND:
        return (
          <>
            {getPricingDetail(t('PLANS.PRICING.exams'), true)}
            {getPricingDetail(t('PLANS.PRICING.course'), true)}
            {getPricingDetail(t('PLANS.PRICING.automatic'), true)}
            {getPricingDetail(t('PLANS.PRICING.pendings'), true)}
            {getPricingDetail(
              t('PLANS.PRICING.complete', {
                writings: writing,
                speakings: speaking
              }),
              true
            )}
            {getClassesDetails()}
          </>
        )
      case plans.MASTER:
        return (
          <>
            {getPricingDetail(t('PLANS.PRICING.exams'), true)}
            {getPricingDetail(t('PLANS.PRICING.course'), true)}
            {getPricingDetail(t('PLANS.PRICING.automatic'), true)}
            {getPricingDetail(t('PLANS.PRICING.pendings'), true)}
            {getPricingDetail(
              t('PLANS.PRICING.complete', {
                writings: writing,
                speakings: speaking
              }),
              true
            )}
            {getClassesDetails()}
          </>
        )

      default:
        return (
          <>
            {getPricingDetail(t('PLANS.PRICING.exams'), false)}
            {getPricingDetail(t('PLANS.PRICING.course'), false)}
            {getPricingDetail(t('PLANS.PRICING.automatic'), true)}
            {getPricingDetail(
              t('PLANS.PRICING.pendings', {
                writings: writing,
                speakings: speaking
              }),
              false
            )}
            {getClassesDetails()}
          </>
        )
    }
  }

  const getColorRight = () => {
    switch (index) {
      default:
        return RedRight

      case 1:
        return BlueRight

      case 2:
        return TurquoiseRight
    }
  }

  const getColorLeft = () => {
    switch (index) {
      default:
        return RedLeft

      case 1:
        return BlueLeft

      case 2:
        return TurquoiseLeft
    }
  }

  const handleSelectPayment = () => {
    dispatch(select(name))

    setCheckout()
  }

  /**
   * @param {Package}
   */
  const handlePaymentRequest = ({ plan }) => {
    ToastsStore.success(
      t('COMPONENTS.BANNER.unblocked', { package: plan.name })
    )

    history.push(PATH.DASHBOARD)
  }

  return (
    <>
      <div className={className}>
        <div className={styles.container}>
          <ReactSVG beforeInjection={svg} src={getColorLeft()} />
          <Text center color="secondary" tag="h6">
            {name}
          </Text>
          <ReactSVG beforeInjection={svg} src={getColorRight()} />
        </div>
        <div className={styles.margin} />
        <Text center color="gray" tag="h3">
          {getPackagePrice(price)} {currency}/
          <Text color="gray" tag="span">
            {t('PLANS.month')}
          </Text>
        </Text>
        {children}
        <ul
          about="selector"
          className="list-unstyled mt-5 text-small text-left text-muted"
        >
          {description()}
        </ul>
        <br />
        <div className="d-flex justify-content-center">
          <Button
            className="rounded-pill"
            block
            size="sm"
            onClick={handleSelectPayment}
          >
            {t('PLANS.purchase')}
          </Button>
        </div>
      </div>
      <Payment
        openWindow={checkout}
        onCloseWindow={setCheckout}
        defaultPaymentMethod
        onPaymentRequest={handlePaymentRequest}
      />
    </>
  )
}

Card.defaultProps = {
  className: null
}

export default Card
