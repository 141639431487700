export const loadingIndicatorEN = {
  audio: 'Loading audio',
  checkingAnswer: 'Checking answer',
  content: 'Loading content..',
  login: {
    signing: 'Signing in'
  },
  message: 'Loading',
  payment: 'Aptis is processing your payment',
  processing: 'Processing log in',
  request: 'AptisGo is processing your request',
  verificating: 'AptisGo is processing your verification',
  results: 'Obtaining results',
  sending: 'Sending',
  updating: 'Updating',
  course: 'Loading course'
}
