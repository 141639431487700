export const settingsES = {
  cancel: 'Cancelar',
  change: 'Cambiar examen',
  confirm: 'Confirmar:',
  edit: 'Editar',
  email: 'Correo',
  error: 'Hubo un error intentando actualizar',
  lastName: 'Apellido',
  model: 'Modelo',
  name: 'Nombre',
  password: 'Actualizar Contraseña',
  save: 'Guardar',
  title: 'Configuración',
  update: 'Información Actualizada'
}
