import api from 'api'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchPlansThunk = createAsyncThunk(
  'plans/fetch',
  async ({ model }, { signal, rejectWithValue }) => {
    try {
      const plans = await api.plans.fetchPlans({ model })

      return plans
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const fetchOffersThunk = createAsyncThunk(
  'plans/offers',
  async ({ model }, { signal, rejectWithValue }) => {
    try {
      const plans = await api.plans.fetchPlans({ offers: true, model, signal })

      return plans
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const fetchPricingThunk = createAsyncThunk(
  'plans/pricing',
  async ({ model }, { signal, rejectWithValue }) => {
    try {
      const pricing = await api.plans.fetchPlans({
        pricing: true,
        model,
        signal
      })

      return pricing
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
