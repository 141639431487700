export const toastNotificationES = {
  automaticallyEnd: 'Tu llamada terminará automaticamente en:',
  mustRecord: 'Debes completar todas las grabaciones para poder continuar',
  completedSpeaking: '¡Muy bien, ahora puedes continuar!',
  uncompletedSpeaking:
    'Debes obtener un paquete para poder continuar con los resultados',
  classEnded: 'Dear user, your class has ended',
  conflict:
    'La dirección de correo electrónico no puede ser la que posees actualmente',
  contentNotAvailable:
    'Estimado usuario, el contenido no se encuentra disponible en estos momentos',
  courseAlreadyTaken: 'Estimado usuario, ya usted dispone de este curso',
  dataUpdated: 'Los datos se han actualizado correctamente',
  emailAlreadyUsed: 'El correo electrónico ya está en uso',
  error: 'Un problema ha ocurrido',
  evaluationEnded: 'Estimado profesor, la evaluación ha culminado',
  exchanged:
    'El código ha sido canjeado exitosamente!, disfruta de tu membresía apartir de ahora.',
  exchangeExpired: 'El código introducido ha caducado',
  expiredPackage: '¡Activa tu paquete ahora para seguir disfrutando del curso!',
  expiredVerificationCode: 'El enlace ha caducado o no es valido',
  fillAnswers: 'Se deben seleccionar las respuestas, y no deben ser iguales',
  gift: '¡El regalo se ha enviado exitosamente! gracias por tu compra.',
  inscription: 'Te has inscrito en el curso satisfactoriamente!',
  internalServerError: 'Problema del servidor interno',
  interpolation: {
    payment:
      'Su pago por el paquete de {{text}} ha sido procesado exitosamente!',
    registered:
      'Su cuenta ha sido registrada en AptisGo, por favor verifique su correo electrónico {{email}}'
  },
  invalidAccount: 'La dirección de la cuenta no existe en nuestros registros',
  invalidExchangeCode: 'El código introducido no es válido',
  invalidRoute: 'La ruta a la que estás accediendo no existe',
  invalidSignUpForm: 'Hubo un error debido a que no completaste la validación',
  invalidTerms: 'Debes aceptar los terminos generales para poder continuar',
  invalidToken: 'Código inválido o caducado',
  minutesLeft:
    'Estimado usuario, restan 4 minutos habiles para que culmine la clase',
  mustFillAnswers: 'Se deben seleccionar las respuestas',
  paymentCourseSuccess:
    '¡Tu pago por el curso Aptis se ha procesado, bienvenido!',
  paymentError: 'El pago no pudo ser procesado',
  paymentFailure: 'Su pago no se ha podido procesar debido a un error',
  paymentSuccess:
    '¡Su pago por el paquete de {{package}} ha sido procesado exitosamente!',
  paymentSuccessfullGo:
    'Su pago por el paquete Go ha sido procesado exitosamente!',
  permissionDenided: 'Error, no tienes permisos para ver este contenido.',
  preparingStreaming: 'Preparándo Streaming',
  record:
    '¡Muy bien, has grabado un audio puedes continuar o volver a intentarlo!',
  requestDenied: 'Aptis no ha podido procesar la solicitud',
  selectPlan:
    'Debes seleccionar un plan y escribir un correo electrónico válido',
  sendedExercise:
    'Tu ejercicio se ha enviado extiosamente, y está pendiente por revisión',
  sendedRecoverEmailNotification:
    'Se ha enviado un enlance de recuperación al usuario',
  sendForgotPasswordMessage: 'Se ha enviado el código de verificación',
  serverProblem:
    'Hemos tenido un problema en el servidor vuelve a unirte a llamada',
  verificated: 'La cuenta ha sido verificada',
  verificationSuccess: 'Hemos verificado su cuenta exitosamente',
  verifyPersonalData: 'Error, verifica tus datos personales',
  welcomeCourseMessage: 'Bienvenido al Curso Aptis!',
  welcomeCourseMessageAfterPayment:
    '¡Bienvenido al curso, se ha completado el pago!',
  welcomeMessage: 'Bienvenido a AptisGo!'
}
