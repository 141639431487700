export const modalES = {
  content: {
    alternativeText: 'Todo el temario de examen de examen Aptis',
    informativeText: 'Para poder obtener el curso subscribete!'
  },
  checkpointPaymentPackage: '¿Estás de acuerdo en pagar el paquete',
  friendGift: 'Comprar paquete para un amigo.',
  getCourse: '¿Desea comprar este curso?',
  getPackage: 'Consigue el paquete',
  stripePayment: 'Comprar paquete para un amigo',
  textHeaderTermsAndConditions: 'Condiciones Generales de Contratación',
  unblockPackage: 'Desbloquear Paquete',
  updatePassword: '¿Estás seguro de actualizar tu contraseña?',
  image: 'Imagen'
}
