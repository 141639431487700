export const tourES = {
  authentication: [
    'Quick access without account. Start practicing!',
    'Access with your Gmail account, it is the fastest. Start practicing!',
    'Fill in this simple form to be able to register in the App and start practicing',
    'Press the enter button to access the application'
  ],
  dashboard: [
    'Welcome to AptisGo! To start practicing the exams, you just have to access here and select the competition you prefer',
    'This is the main section, the house, you can access the exams, evaluations and statistics',
    "Here you will find the interactive course, a complete Aptis course with all the content included. It's self-correcting and fun",
    'Here you will find the packages that best suit what you want to practice. We adapt to you.',
    "Here you can book a private class with an expert native teacher in exams, online and when it suits you. Don't forget to check it out!",
    'Here you can change your email or password, contact us by WhatsApp, exchange / send gifts and log out',
    'In this section you will find the latest news about the application',
    "Here you can see your teacher's Speaking / Writings evaluations",
    'In this section you can see your statistics on your performance in the application',
    'In this section you will see your statistics on your performance by category',
    "Don't forget that practice makes perfect. Good luck!"
  ],
  classes: [
    "Here you can check the schedule of a class that you have already booked. If it's empty, don't be alarmed!",
    'Enter here to check if there are any hours that suit you. Without obligation!'
  ]
}
