export const dashboardES = {
  welcome: 'Bienvenido',
  classes: {
    online: 'Online',
    classes: 'CLASES',
    individuals: 'Clases Particulares',
    experts: 'Profesores expertos',
    consult: 'Horario',
    reserve: 'Reservar'
  },
  stats: {
    title: 'Estadísticas',
    see: 'Ver Todo',
    exam: 'E'
  },
  evaluations: {
    category: 'Category',
    date: 'Fecha',
    options: 'Opciones',
    status: 'Estado'
  },
  notification: `
  Ya están actualizados los 10 exámenes a los cambios oficiales
  Aptis 2020. ¡Somos los primeros en actualizarlo en todo internet!`,
  users: {
    latest: {
      header: 'Lo último',
      subheader: 'Tu progreso anteriormente'
    },
    evaluations: {
      header: 'Evaluaciones',
      management: {
        evaluated: 'Evaluado'
      },
      messages: {
        notAvailable: 'No hay resultados disponibles'
      },
      subheader: 'Tu progreso actualmente'
    },
    exams: {
      header: 'Exámenes',
      messages: {
        notAvailable: 'No hay exámenes disponibles'
      },
      subheader: '¡Comienza a practicar ahora mismo!',
      textName: 'Exámen'
    },
    material: {
      main: 'Materiales de estudio',
      sub: 'Examen de Aptis'
    },
    rows: {
      category: 'Categoría',
      evaluation: 'Evaluado',
      options: 'Opciones',
      order: '#',
      state: 'Estado'
    },
    stats: {
      main: 'Estadísticas',
      secondary: 'Por categoría'
    }
  }
}
