export const EVALUATION_COMPLETED = 'EvaluationCompleted'
export const EVALUATION_PENDING = 'EvaluationPending'

export const FEEDBACK = 'Feedback'

export const MEETING_START = 'MeetingStart'
export const MEETING_EXPIRED = 'MeetingExpired'

export const SCHEDULE = 'Schedule'
export const DELETE_SCHEDULE = 'DeleteSchedule'


export const PAYMENT_COMPLETED = 'PaymentCompleted'
export const PAYMENT_EXPIRED = 'PaymentExpired'
export const PAYMENT_NOTIFY = 'PaymentNotify'

