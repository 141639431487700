export const componentsEN = {
  COURSES: {
    start: 'Start',
    name: 'COURSES',
    interactive: '{{model}} interactive'
  },
  EVALUATIONS: {
    title: 'Evaluations',
    notavailable: 'No evaluations available',
    rows: {
      category: 'Category',
      date: 'Date',
      status: 'Status',
      options: 'Options'
    }
  },
  BANNER: {
    unblock: 'Unlock',
    details: 'See pricing',
    unblocked: '¡Congratulations you have unlocked your {{package}}, enjoy!'
  },
  EXAMS: {
    title: 'EXAMS!',
    description: 'Start {{practice}} for {{model}} today!',
    practice: 'practicing'
  },
  RECORDER: {
    unsync: 'Enable your microphone to start recording',
    play: 'Press to record',
    stop: 'Stop'
  },
  freeLogin: 'Login without account',
  withOtherEmail: 'Start with another email',
  SPEAKING: {
    next: 'Next question',
    stepOne: 'Well done! Let´s continue!',
    stepThree: 'Well done!',
    stepTwo: 'Completed! Send your results now!'
  },
  payment: {
    cancel: 'Cancel',
    action: 'Make payment',
    content: 'See details',
    details: 'Do you wish to buy the package {{plan}}?',
    invoice: 'Generating receipt..',
    placeholder: 'Insert your credit card details',
    processing: 'Payment in process {{model}}',
    subscribe: 'Subscribe to see your results'
  },
  chat: {
    isWriting: '{user} is writing..',
    placeholder: 'Write a message..'
  },
  MEDIA_STREAM_ERROR: {
    line1:
      'B1B2top was unable to connect your videocall, your device cannot be found.',
    line2: 'Make sure',
    line3: 'Your Webcam is connected.',
    line4: 'Check that your microphone is connected',
    line5:
      'Once you have checked your camera and your microphone refresh the page.',
    line6:
      'If you need to, you can use the chat box to notify your teacher that you are having connection problems.',
    reconnect: 'Reconnect'
  },
  NOTIFICATIONS: {
    title: 'Notifications ',
    unavailable: 'No available notifications',
    goto: 'Go to notifications'
  },
  NAVIGATION: {
    exam: 'Exams',
    config: 'Settings',
    logout: 'Logout',
    whatsapp: 'What´sApp',
    telegram: 'Telegram'
  },
  audioRecorder: {
    recording: 'Saving Answer',
    unsyncMic: 'Enable your microphone to record',
    recordToContinue: 'To continue, save your answer',
    completedRecords: 'Very well you have completed your recordings!'
  },
  completedExercise: {
    review: 'Review'
  },
  completedUnit: {
    placeholder: {
      congrats: 'Congratulations, unit completed!',
      effort: 'You are making a big effort',
      results: 'And all efforts have their results.',
      machine: 'Great, you are a machine!'
    }
  },
  FOOTER: {
    match: 'Correct answer'
  },
  formPassword: {
    placeholder: {
      confirm: 'Confirm Password',
      new: 'New Password'
    },
    button: {
      update: 'Update'
    },
    validation: {
      match: 'The passwords match',
      mustMatch: 'The passwords must match, and contain at least 8 charecters'
    }
  },
  materialInfo: {
    speaking: 'Every thing about the Aptis Speaking test',
    writing: 'How to correctly do the Aptis Writing Test '
  },
  navigation: {
    dropdown: {
      config: 'Set up',
      exchange: 'Exchange',
      logOut: 'Log out',
      whatsApp: 'WhatsApp'
    },
    tabs: {
      courses: 'COURSES',
      dashboard: 'DASHBOARD',
      gift: 'GIFT',
      payments: 'PAYMENTS'
    }
  },
  paymentForm: {
    placeholders: {
      _1: 'Enter your credit card information',
      _2: 'Make payment',
      _3: 'Generating receipt..',
      _4: 'Aptis is processing the payment'
    }
  },
  reading: {
    message:
      'You can order the text by selecting a sequence and drag them one over the other, Try it!'
  },
  requiredPayment: {
    placeholders: {
      _1: 'Loading plans...',
      rows: {
        content: 'Course content',
        price: 'Price',
        type: 'Type'
      }
    }
  },
  speakings: {
    button: {
      next: 'Next Question'
    },
    placeholders: {
      _1: 'Very good, we can continue!',
      _2: 'Completed, send your results now!',
      _3: 'Well done!'
    }
  },
  testDetail: {
    text: 'Take test'
  }
}
