import React, { useEffect, useState } from 'react'
import classNames from 'clsx'
import Icon from 'react-icons-kit'
import { useDispatch } from 'react-redux'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { check } from 'react-icons-kit/fa/check'
import { times } from 'react-icons-kit/fa/times'
import { chevronCircleRight } from 'react-icons-kit/fa/chevronCircleRight'
import { chevronCircleLeft } from 'react-icons-kit/fa/chevronCircleLeft'

import { withVerification as WV } from 'hocs'

import usePlans from 'hooks/usePlans'
import useModel from 'hooks/useModels'
import usePricing from 'hooks/usePricing'

import { Button } from 'styled'

import { fetchPlansThunk, fetchPricingThunk } from 'store/@thunks/plans'

import useAccess from 'hooks/useAccess'
import useMedia from 'hooks/useMedia'

import Template from 'components/Template'
import Text from 'components/Text'
import Cards from './components/Cards'

import styles from './plans.module.scss'
import { getItemStyle } from 'components/Exams/utils'
import { getPackagePrice } from 'utils/functions'
import { BLUE } from 'assets/colors'
import { select } from 'store/@reducers/plans'
import useToggler from 'hooks/useToggler'
import Payment from 'components/Payment'
import { ToastsStore } from 'react-toasts'
import PATH from 'utils/path'
import useCounter from 'hooks/useCounter'

const Plans = () => {
  const history = useHistory()

  const { accesses, haveAccess } = useAccess()

  const isMobile = useMedia(`(max-width: 799px)`, true)

  const dispatch = useDispatch()

  const plans = usePlans()

  const pricing = usePricing()

  const { t } = useTranslation()

  const { model } = useModel()

  const [checkout, setCheckout] = useToggler()

  const { count, update, isZero, isReached }= useCounter(0, 0)

  const [ref, setRef] = useState(null)

  
  useEffect(() => {
    if (plans.data.length > 0) {
      update.limit(plans.data.length - 1)

      setRef(plans.data[0])
    }
  }, [plans.data, update])



  useEffect(() => {
    if (isZero === false) {
      setRef(plans.data[count.value])
    }
  }, [count.value, isZero, plans.data])

  /**
   * @description
   * Fetching plans
   */
  useEffect(() => {
    if (model) {
      dispatch(
        fetchPlansThunk({
          model: model.name
        })
      )
    }
  }, [dispatch, model])

  /**
   * @description
   * Fetching pricing
   */

  useEffect(() => {
    if (model) {
      dispatch(
        fetchPricingThunk({
          model: model.name
        })
      )
    }
  }, [dispatch, model])

  /**
   * @description
   * These functions only works for grant access to certain features
   */

  const haveAccessToExam = ({ feature }) => {
    return feature === 'EXAMS'
  }

  const haveAccessToCourse = ({ feature }) => {
    return feature === 'COURSES'
  }

  const haveAccessToEvaluation = ({ feature }) => {
    return feature === 'EVALUATIONS'
  }

  /**
   * @param {string} plan 
   */
  const handleSelectPayment = (plan) => {
    dispatch(select(plan))

    setCheckout()
  }

  /**
   * @param {Package} payment 
   */
  const handlePaymentRequest = ({ plan }) => {
    ToastsStore.success(
      t('COMPONENTS.BANNER.unblocked', { package: plan.name })
    )

    history.push(PATH.DASHBOARD)

    setCheckout()
  }


  return (
    <>
      <Template withLoader={plans.loading || pricing.loading} view>
        <Row>
          {pricing.data.map((plan, index) => (
            <Col lg={4} className={styles.title} key={plan.id}>
              <div className={classNames('bg-white', 'shadow', styles.box)}>
                <Cards
                  {...plan}
                  className={styles[getItemStyle(index)]}
                  index={index}
                >
                  <div about={plan.name} className="my-4 mx-auto">
                    <img
                      alt="panda"
                      className="img-fluid"
                      src={plan.pandaUrl}
                      width={50}
                    />
                  </div>
                </Cards>
              </div>
            </Col>
          ))}
          <br />
        </Row>
        <br />
        <Row className={styles.root}>
          <Col>
            <Card>
              <Card.Header
                className={classNames('rounded', 'border-0', styles.cardheader)}
              >
                {t('PLANS.title')}
              </Card.Header>
              {isMobile && plans.selected ? (
                <table className="table align-items-center  table-flush">
                  <thead className={styles.theader}>
                    <tr className={styles.data}>
                      <th colSpan="2">
                        <div className={styles.mobile}>
                          <div>
                            {isZero || (
                              <Icon size={30} icon={chevronCircleLeft} onClick={update.decrement} />
                            )}
                          </div>
                          <div>
                            <Text center dunkin color="blue" tag="small">
                              {plans.selected.name}
                            </Text>
                          </div>
                          <div>
                            {isReached || (
                              <Icon size={30} icon={chevronCircleRight} onClick={update.increment} />
                            )}
                          </div>
                        </div>
                        <Text dunkin center color="blue" tag="h5">
                          {plans.selected.taxe
                            ? getPackagePrice(plans.selected.taxe)
                            : getPackagePrice(plans.selected.price)}
                        </Text>
                      </th>
                    </tr>
                    <tr className={styles.data}>
                      <th colSpan="2">
                        <Text dunkin tag="span">
                          Servicios
                        </Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className={styles.vertical}>
                        {t('PLANS.exams')}
                        {plans.selected.access.find(haveAccessToExam) ? (
                          <Icon className="text-success" icon={check} />
                        ) : (
                          <Icon className="text-danger" icon={times} />
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.vertical}>
                        {t('PLANS.classes')}
                        {plans.selected.classes ? (
                          <Icon className="text-success" icon={check} />
                        ) : (
                          <Icon className="text-danger" icon={times} />
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.vertical}>
                        {t('PLANS.course')}
                        {plans.selected.access.find(haveAccessToCourse) ? (
                          <Icon className="text-success" icon={check} />
                        ) : (
                          <Icon className="text-danger" icon={times} />
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.vertical}>
                        {t('PLANS.evaluations')}
                        {plans.selected.access.find(haveAccessToEvaluation) ? (
                          <Icon className="text-success" icon={check} />
                        ) : (
                          <Icon className="text-danger" icon={times} />
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.vertical}>
                        {t('PLANS.speakings')}
                        {plans.selected.speaking ? (
                          <Icon className="text-success" icon={check} />
                        ) : (
                          <Icon className="text-danger" icon={times} />
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.vertical}>
                        {t('PLANS.writings')}
                        {plans.selected.writing ? (
                          <Icon className="text-success" icon={check} />
                        ) : (
                          <Icon className="text-danger" icon={times} />
                        )}
                      </th>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table className="table align-items-center  table-flush">
                  <thead className={styles.theader}>
                    <tr className={styles.data}>
                      <th scope="col">{t('PLANS.package')}</th>
                      <th scope="col">{t('PLANS.exams')}</th>
                      <th scope="col">{t('PLANS.speakings')}</th>
                      <th scope="col">{t('PLANS.writings')}</th>
                      <th scope="col">{t('PLANS.classes')}</th>
                      <th scope="col">{t('PLANS.course')}</th>
                      <th scope="col">{t('PLANS.evaluations')}</th>
                      <th scope="col">{t('PLANS.price')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {plans.data.map(plan => (
                      <tr className={styles.data} key={plan.name}>
                        <th scope="row">
                          <div className="media align-items-center">
                            {plan.image && <div />}
                            <div className="d-flex justify-content-between media-body">
                              <Button background={BLUE} onClick={() => handleSelectPayment(plan.name)}>
                                {t('PLANS.purchase')}
                              </Button>
                              <Text color="blue" tag="small">
                                {plan.name}
                              </Text>
                            </div>
                          </div>
                        </th>
                        <td>
                          {haveAccess(plan, [accesses.EXAMS]) ? (
                            <Icon className="text-success" icon={check} />
                          ) : (
                            <Icon className="text-danger" icon={times} />
                          )}
                        </td>
                        <td>
                          <Text bold color="blue" tag="small">
                            {plan.speaking}
                          </Text>
                        </td>
                        <td>
                          <Text bold color="blue" tag="small">
                            {plan.writing}
                          </Text>
                        </td>
                        <td>
                          <Text bold color="blue" tag="small">
                            {plan.classes}
                          </Text>
                        </td>
                        <td>
                          {haveAccess(plan, [accesses.COURSES]) ? (
                            <Icon className="text-success" icon={check} />
                          ) : (
                            <Icon className="text-danger" icon={times} />
                          )}
                        </td>
                        <td>
                          {haveAccess(plan, [accesses.EVALAUTIONS]) ? (
                            <Icon className="text-success" icon={check} />
                          ) : (
                            <Icon className="text-danger" icon={times} />
                          )}
                        </td>
                        <td>
                          <Text bold color="blue" tag="small">
                            {plan.taxe
                              ? getPackagePrice(plan.taxe)
                              : getPackagePrice(plan.price)}{' '}
                            <img
                              className="img-fluid"
                              alt="source"
                              src={plan.currencyImage}
                              width={20}
                            />{' '}
                            / {t('PLANS.month')}
                          </Text>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Card>
          </Col>
        </Row>
      </Template>
      <Payment 
        defaultPaymentMethod
        openWindow={checkout}
        onCloseWindow={setCheckout}
        onPaymentRequest={handlePaymentRequest}
      />
    </>
  )
}

export default WV(Plans)
