export const exchangeEN = {
  code: 'Aptis Code',
  form: {
    placeholder: {
      code: 'Enter the code'
    }
  },
  info: 'Redeeming your gift is very easy!',
  instructions:
    'You must enter the code you received and press "Redeem gift" and that is it!',
  submit: 'Redeem gift'
}
