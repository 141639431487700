/**
 * @description
 * Spanish models feature
 */

export const modelsES = {
  title: 'Bienvenido',
  subtitle: '¿Cuál exámen quieres prácticar?',
  Aptis:
    'Cuenta con la confianza de gobiernos y organizaciones de más de 85 países.',
  IELTS:
    'Reconocido por más de 10,000 instituciones en todo el mundo. Realiza el IELTS con fines laborales y educativos.'
}
