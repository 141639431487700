export const agreementEN = {
  CONFIRMED: {
    about:
      'Thank you! the teacher has received your wishes and is preparing your class',
    mail: 'You will receive an email reminding you of the date of your class.',
    notification:
      '10 minutes before class you will receive notification so you can access your classroom. Everything within AptisGo.',
    reminder:
      'Remember to be on time, classes cannot change the time or be canceled.',
    title: 'Confirmed! You already have your class scheduled'
  },
  CUSTOMIZE: {
    about: 'What do you want the class to be about?',
    confirm: 'Confirm',
    level: 'What English level do you have?',
    title: 'And finally ... customize your class'
  },
  PICKER: {
    description:
      'You can choose which language you would like the teacher to use in your class.',
    isNotFirstTime: {
      description:
        'If you have already had a class with us, choose a teacher to see their availability:',
      subheader:
        'If you have not had a class with us yet, return to the previous window.',
      title: 'Select your teacher'
    },
    languagePicker: {
      _1:
        'If you choose - Spanish, the teacher will be able to give you the class in English and Spanish.',
      _2: 'If you choose - English, the teacher will only speak in English'
    },
    option: 'Choose an option:',
    title: 'Would you like to book your class with the same teacher?'
  },
  PRIMARY: {
    about: 'With just a few clicks you can organize an English class with an Aptis expert. You can choose to have the teacher speak in Spanish and English or only in English. You can customize your class by choosing the subject or competition of the exam you want the teacher to focus on. You only need to have an hour available and a stable internet connection. You won´t have to download anything, all here from home in AptisGo.',
    confirm: 'Confirm a private class',
    foryou: 'just for you',
    customize: 'Customize',
    class: 'your class',
    few: 'In just',
    clicks: 'a few clicks',
    prepare: 'Prepare specific',
    specific: 'test skills',
    title: 'Become an {{model}} Master'
  },
  back: 'Back',
  isNotMyFirstTime: "It's not my first time",
  next: 'Next',
  title: 'You can choose the schedule that you like the most!',
  subscribe: "Choose the plan that is best for you",
  purchase: "Congratulations, now you can take your class!"
}
