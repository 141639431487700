/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Row, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import classNames from 'clsx'

import useAuthProvider from 'hooks/useAuthProvider'
import useCategories from 'hooks/useCategories'
import useEvaluations from 'hooks/useEvaluations'
import useExams from 'hooks/useExams'
import useSVG from 'hooks/useSVG'
import useStats from 'hooks/useStats'
import useModels from 'hooks/useModels'
import useOffers from 'hooks/useOffers'
import usePage from 'hooks/usePage'

import Banner from 'components/Banner'
import Classes from 'components/Classes'
import Courses from 'components/Courses'
import Evaluations from 'components/Evaluations'
import Exams from 'components/Exams'
import Stats from 'components/Stats'
import Template from 'components/Template'
import Text from 'components/Text'
import Meeting from 'components/Meeting'


import { StyledCol } from './styles/user'
import { fetchEvaluationsThunk } from 'store/@thunks/evaluations'

import styles from './index.module.scss'
import { svg } from 'assets/svg'
import Register from 'components/Register'
import PATH from 'utils/path'

const UserView = () => {
  const history = useHistory();

  const categories = useCategories()

  const { fetchExams, ...exams } = useExams()

  const { fetchStats, ...stats } = useStats()

  const { fetchOffers, ...offers } = useOffers()

  const { fetchEvaluations, ...evaluations } = useEvaluations()

  const { t } = useTranslation()

  const { page, handleSet } = usePage()

  const { model } = useModels()

  const user = useAuthProvider()

  const dispatch = useDispatch()

  const svgCallback = useSVG({
    attributes: [
      ['width', '90%'],
      ['height', 290]
    ]
  })

  useEffect(fetchOffers, [fetchOffers])

  useEffect(fetchExams, [fetchExams])

  useEffect(fetchStats, [fetchStats])

  useEffect(() => {
    if (model) {
      dispatch(
        fetchEvaluationsThunk({
          own: false,
          page,
          model: model.name
        })
      )
    }
  }, [page, model])



  return (
    <React.Fragment>
      <Template
        withLoader={
          stats.loading ||
          exams.loading ||
          categories.loading ||
          evaluations.loading ||
          offers.loading
        }
        withVideos
      >
        <Container>
          <Register enabled={user.demo} onClose={() => history.push(PATH.MODELS)} />
          <div className={styles.grid}>
            <div className={classNames(styles.banner, 'd-none d-sm-block')}>
              <div>
                <ReactSVG beforeInjection={svgCallback} src={svg.dialog} />
              </div>
              <Text className={styles.welcome} color="blue" tag="p">
                {t('DASHBOARD.welcome')} <br />{' '}
                <span>{user.profile.firstName}</span>
              </Text>
            </div>
            <Banner />
          </div>
          <hr />
          <Meeting />
          <Row>
            <StyledCol md={12}>
              <Exams />
            </StyledCol>
          </Row>
          <Row>
            <StyledCol xs={12} sm={12} md={5}>
              <Stats show />
            </StyledCol>
            <StyledCol xs={12} sm={12} md={7}>
              <Courses />
            </StyledCol>
          </Row>
          <Row>
            <StyledCol xs={12} sm={12} md={7}>
              <Classes />
            </StyledCol>
            <StyledCol xs={12} sm={12} md={5}>
              <Evaluations onRenderPage={handleSet} show />
            </StyledCol>
          </Row>
        </Container>
      </Template>
    </React.Fragment>
  )
}

export default UserView
