export const exchangeES = {
  code: 'Código Aptis',
  form: {
    placeholder: {
      code: 'Introduce el código'
    }
  },
  info: '¡Canjear tu regalo es muy sencillo!',
  instructions:
    'Debes introducir el código que recibiste y presionas "Canjear regalo" y ¡listo!',
  submit: 'Canjear Regalo'
}
