import path from 'utils/path'

const links = [
  {
    ref: 'dashboard',
    route: path.DASHBOARD
  },
  {
    ref: 'pricing',
    route: path.PAYMENTS
  },
  {
    ref: 'classes',
    route: path.CLASSES
  },
  {
    ref: 'courses',
    route: path.COURSES
  }
]

export default links