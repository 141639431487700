export const CORE = 'Grammar & Vocabulary'
export const GRAMMAR = 'Grammar'
export const VOCABULARY = 'Vocabulary'
export const LISTENING = 'Listening'
export const LISTENING_PART_1 = 'Listening Part 1'
export const LISTENING_PART_2 = 'Listening Part 2'
export const LISTENING_PART_3 = 'Listening Part 3'
export const READING = 'Reading'
export const READING_PART_1 = 'Reading Part 1'
export const READING_PART_2 = 'Reading Part 2'
export const READING_PART_3 = 'Reading Part 3'
export const READING_PART_4 = 'Reading Part 4'
export const READING_PART_5 = 'Reading Part 5'
export const SPEAKING = 'Speaking'
export const SPEAKING_PART_1 = 'Speaking Part 1'
export const SPEAKING_PART_2 = 'Speaking Part 2'
export const SPEAKING_PART_3 = 'Speaking Part 3'
export const SPEAKING_PART_4 = 'Speaking Part 4'
export const WRITING = 'Writing'
export const WRITING_PART_1 = 'Writing Part 1'
export const WRITING_PART_2 = 'Writing Part 2'
export const WRITING_PART_3 = 'Writing Part 3'
export const WRITING_PART_4 = 'Writing Part 4'