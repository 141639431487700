export const modalEN = {
  content: {
    alternativeText: 'All the Aptis exam syllabus',
    informativeText: 'To get the entire course, subscribe now!'
  },
  checkpointPaymentPackage: 'Do you agree to pay the package?',
  friendGift: 'Buy package for a friend.',
  getCourse: 'Do you wish to buy this course?',
  getPackage: 'Get the package',
  stripePayment: 'Buy package for a friend',
  textHeaderTermsAndConditions: 'General Contracting Conditions',
  unblockPackage: 'Unlock Package',
  updatePassword: 'Are you sure you want to update your password?',
  image: 'Image'
}
