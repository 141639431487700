/**
 * @see https://colorpeek.com/#FFB208
 */
export const YELLOW = '#FFB208'

/**
 * @see https://colorpeek.com/#003E6D
 */
export const BLUE = '#003E6D'

/**
 * @see https://colorpeek.com/#00CCC6
 */
export const TURQUOISE = '#00CCC6'

/**
 * @see https://colorpeek.com/#FF3F3F
 */
export const RED = '#FF3F3F'

/**
 * @see https://colorpeek.com/#E88178
 */
export const PINK = '#E88178'


/**
 * @see https://colorpeek.com/#707070
 */
export const GRAY = '#707070'


/**
 * @see https://colorpeek.com/#FFFFFF
 */
export const WHITE = '#FFFFFF'



export const GREEN = '#58a700'


export const ORANGE = 'orange'


export const LIGHTBLUE = '#17a2b8'


export const PURPLE ='#b331b5'