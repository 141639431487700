export const toastNotificationEN = {
  automaticallyEnd: 'Your call will automatically end in:',
  mustRecord: 'You must complete all the recordings to be able to continue',
  completedSpeaking: 'Very well, now you can continue!',
  uncompletedSpeaking: 'You must obtain a package to continue with the results',
  classEnded: 'Estimado usuario, su clase ha culminado',
  conflict: 'The email address cannot be the one you currently have',
  contentNotAvailable: 'Dear user, the content is not available at the moment',
  courseAlreadyTaken: 'Dear user, you have already taken this course',
  dataUpdated: 'The data has been updated successfully',
  emailAlreadyUsed: 'The Email is already in use',
  error: 'A problem has occurred',
  evaluationEnded: 'Dear Teacher, the evaluation has finished',
  exchanged:
    'The code has been successfully exchanged! You can now enjoy your membership.',
  exchangeExpired: 'The entered code has expired',
  expiredPackage: 'Activate your package now to continue enjoying the course!',
  expiredVerificationCode: 'The link has expired or is not valid',
  fillAnswers: 'You must choose the answers, and they cannot be the same',
  gift: 'The gift has been sent successfully! thanks for your purchase.',
  inscription: 'You have successfully enrolled in the course!',
  internalServerError: 'Internal server problem',
  interpolation: {
    payment:
      'Your payment for the package{{text}}has been successfully processed!',
    registered:
      'Your account has been registered in AptisGo, please check your email {{email}}'
  },
  invalidAccount: 'The account address does not exist in our records',
  invalidExchangeCode: 'The entered code is not valid',
  invalidRoute: 'The path you are accessing does not exist',
  invalidSignUpForm:
    'There was an error because you did not complete the validation',
  invalidTerms: 'You must accept the general terms to continue',
  invalidToken: 'Invalid or expired code',
  missingAnswers: 'Answers must be selected',
  minutesLeft:
    'Estimado usuario, restan 4 minutos habiles para que culmine la clase',
  mustFillAnswers: 'Answers must be selected',
  paymentCourseSuccess:
    'Your payment for the Aptis course has been processed, welcome!',
  paymentError: 'Payment could not be processed',
  paymentFailure: 'Your payment could not be processed due to an error',
  paymentSuccess:
    'Your payment for the package {{package}} has been successfully processed! ',
  paymentSuccessfullGo:
    'Your payment for the Go package has been successfully processed!',
  permissionDenided: 'Error, you do not have permissions to view this content.',
  preparingStreaming: 'Preparing Streaming',
  record:
    'Very well, you have recorded an audio, you can continue or try again!',
  requestDenied: 'Aptis could not process the request',
  selectPlan: 'You must select a plan and write a valid email',
  sendedExercise:
    'Your exercise has been submitted successfully, and is pending for review',
  sendedRecoverEmailNotification: 'A recovery link has been sent to the user',
  sendForgotPasswordMessage: 'The verification code has been sent',
  serverProblem: 'We have had a problem on the server please rejoin the call',
  verificated: 'The account has been verified',
  verificationSuccess: 'We have successfully verified your account',
  verifyPersonalData: 'Error, verify your personal data',
  welcomeCourseMessage: 'Welcome to the Aptis Course!',
  welcomeCourseMessageAfterPayment:
    'Welcome to the course, payment has been completed!',
  welcomeMessage: 'Welcome to AptisGo!'
}
