export const feedbackEN = {
  completedOf: 'Completed',
  tryAgain: 'Try again!',
  score: '¡You have received a {{score}}!',
  dashboard: 'Go to dashboard',
  info: {
    completed: 'Completed!',
    got: 'You have received a {{marking}}!',
    nailed: 'Well done',
    pending:
      'Your answers will be previously evaluated by a teacher, we will notify you when they are ready.',
    click: 'You can view the course of your evaluation by clicking here.',
    see: 'See evaluation'
  },
  graphics: {
    total: 'Totals',
    points: 'Correct'
  }
}
