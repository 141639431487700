/**
 * @description
 * English models language service
 */

export const modelsEN = {
  title: 'Welcome',
  subtitle: 'Which test do you wish to practice?',
  Aptis:
    'Aptis is trusted by governments and organizations in more than 85 countries.',
  IELTS:
    'Recognized by more than 10.000 institutions worldwide. Take IELTS for work and educational purposes.'
}
