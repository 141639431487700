import React, { memo, useCallback } from 'react'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { arrowLeft } from 'react-icons-kit/fa/arrowLeft'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'



import Text from 'components/Text'
import Animate from 'components/Animate'
import AptisButton from '../../exams/components/Button'

import useForm from 'hooks/useForm'

import { validateOnePassword } from 'views/settings/validation'
import 'assets/css/input.css'

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`


const FormPassword = ({ onSubmit }) => {
  const { t } = useTranslation()

  const [form, onChange] = useForm({
    password: '',
    confirm: ''
  })

  /**
   * @description
   * Returns the result between the two passwords.
   * @returns {boolean}
   */
  const compare = useCallback(() => {
    return form.password === form.confirm
  }, [form.password, form.confirm])

  /**
   * @param {React.FormEvent<HTMLFormElement>} e
   * @returns {void}
   */
  const onSubmitHandler = useCallback(e => {
    e.preventDefault()

    onSubmit({
      ...form
    })
  }, [form, onSubmit])

  return (
    <Animate type="fadeIn">
      <Text bold tag="h5" className="mb-3">
        <Link to={'/account/dashboard/settings'}>
          <Icon icon={arrowLeft} className="pr-2" />
        </Link>
      </Text>
      <Form onSubmit={onSubmitHandler}>
        <Form.Group>
          <Form.Control
            required
            placeholder={t('AUTHENTICATION.password')}
            className="input input-first"
            autoComplete={'false'}
            name="password"
            onChange={onChange}
            type="password"
            value={form.password}
            isValid={form.password !== '' && compare()}
            isInvalid={
              !validateOnePassword(form.password) && !compare()
            }
          />

          <Form.Control
            required
            placeholder={t('AUTHENTICATION.confirm')}
            className="input input-last"
            autoComplete={'false'}
            name="confirm"
            onChange={onChange}
            isValid={form.confirm !== '' && compare()}
            isInvalid={!validateOnePassword(form.confirm) && !compare()}
            type="password"
            value={form.confirm}
          />
        </Form.Group>
      </Form>
      {(!compare() ||
        !validateOnePassword(form.password) ||
        !validateOnePassword(form.confirm)) && (
        <Animate type="fadeIn">
          <Text color="danger" tag="small">
            {t('AUTHENTICATION.passwordMustMatch')}
          </Text>
        </Animate>
      )}
      {compare() && (form.password !== '' || form.confirm !== '') && (
        <Animate type="fadeIn">
          <Text color="success" tag="small">
            {t('AUTHENTICATION.confirm')}
          </Text>
        </Animate>
      )}
      <ButtonContainer>
        <AptisButton onClick={onSubmitHandler} type="submit">
          {t('AUTHENTICATION.update')}
        </AptisButton>
      </ButtonContainer>
    </Animate>
  )
}

export default memo(FormPassword)
