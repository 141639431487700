import React from 'react'

import Template from 'components/Template'


const Exchange = () => {
  return (
    <Template view />
  )
}

export default Exchange