import styled from 'styled-components'

export const CoreContainer = styled.div`
  margin-bottom: 5px;
`

export const ListeningContainer = styled.div`
  margin-bottom: 10px;
`

export const ReadingContainer = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
`

export const WritingContainer = styled.div`
  margin-bottom: calc(10px + 10vh);
  padding-bottom: 7.5px;
`