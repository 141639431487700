/**
 * @module StreamingReducer
 */

/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const LANGUAGE_CHANGE = 'LENGUAGE_CHANGE'

/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const TEACHER_CHANGE = 'TEACHER_CHANGE'

/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const MEETING_CHANGE = 'MEETING_CHANGE'

/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const SCHEDULE_DATE = 'SCHEDULE_DATE'
/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const FETCH_BLOCKED_DATES = 'LENGUAGE_CHANGE'
/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const GET_PROFESORS = 'GET_PROFESORS'
/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const PETITION_LOADING_START = 'PETITION_LOADING_START'
/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const PETITION_LOADING_END = 'PETITION_LOADING_END'
/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const NEXT_VIEW = 'NEXT_VIEW'
/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const PREVIOUS_VIEW = 'PREVIOUS_VIEW'
/**
 * @description
 * This event works after succesfull Streaming action.
 * @memberof StreamingReducer
 */
export const GROUP_VIEWS = 'GROUP_VIEWS'

export const SET_NEXT_SELECTION = 'SET_NEXT_SELECTION'

export const SET_INIT_SELECTION = 'SET_INIT_SELECTION'

export const ADD_SINGLE_SELECTION_PROFESOR = 'ADD_SINGLE_SELECTION_PROFESOR'

export const ADD_SINGLE_SELECTION_LENGUAGE = 'ADD_SINGLE_SELECTION_LENGUAGE'

export const SET_VIEWS = 'SET_VIEWS'
