import React, { memo, useCallback } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ToastsStore } from 'react-toasts'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'




import { withLogout } from 'hocs'

import FormPassword from './components/FormPassword'
import Template from 'components/Template'
import Text from 'components/Text'


import useQuery from 'hooks/useQuery'
import useQueryValidation from 'hooks/useQueryValidation'

import useToggler from 'hooks/useToggler'

import api from 'api'
import { img } from 'assets/img'
import PATH from 'utils/path'

const ResetPassword = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const { code } = useQuery()

  const [loading, setLoading] = useToggler()

  useQueryValidation(
    {
      required: ['code']
    },
    exception => {
      if (exception) {
        history.push({
          pathname: '/'
        })
      }
    }
  )

  /**
   *
   * @description after submitting al data from <FormPassword />
   * Will call an action to check that's everything is okay.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
   */
  const onSubmitHandler = useCallback(
    /**
     * @param {{ password: string }} formData
     */
    async ({ ...form }) => {
      setLoading(true)

      try {
        await api.auth.resetPassword({
          code,
          password: form.password
        })
        
        ToastsStore.success(
          t('AUTHENTICATION.updateQuickPassword')
        )

        history.push(PATH.LOGIN)
      } catch (err) {
        ToastsStore.warning(
          t('AUTHENTICATION.updateFailedPassword')
        )
      } finally {
        setLoading(false)
      }
    },
    [code, history, setLoading, t]
  )

  return (
    <Template
      withLoader={loading}
      withNavbar={false}
      view
    >
      <br />
      <br />
      <Text dunkin center tag="p" color="muted">
        {t('AUTHENTICATION.resetPasswordTitle')}
      </Text>
      <Text center>
        <img width={300} src={img['logo-APTIS']} alt="logo" />
      </Text>
      <Row>
        <Col md={5} lg={5} xl={5} xs={8} className="mx-auto">
          <FormPassword onSubmit={onSubmitHandler} />
        </Col>
      </Row>
    </Template>
  )
}

export default compose(withLogout, memo)(ResetPassword)