import Main from 'views'
import Agreement from 'views/agreement'
import AdminSchedule from 'views/classes/AdminSchedule'
import Classes from 'views/classes'
import Exams from 'views/exams'
import Evaluations from 'views/evaluations'
import Exchange from 'views/exchange'
import Error402 from 'views/errors/402'
import Verification from 'views/mails/Verification'
import SignUp from 'views/authentication/SignUp'
import Dashboard from 'views/dashboard/Dashboard'
import FetchError from 'views/errors/FetchError'
import Exercise from 'views/exams/Exercise'
import Settings from 'views/settings/Settings'
import Feedback from 'views/feedback'
import Login from 'views/authentication/Login'
import Logout from 'views/authentication/Logout'
import ResetPassword from 'views/authentication/ResetPassword'
import Meetings from 'views/meetings'
import SettingsPassword from 'views/settings/SettingsPassword'
import Courses from 'views/courses'
import Quality from 'views/quality'
import Plans from 'views/plans'
import Gifts from 'views/gifts'
import Models from 'views/models'
import LatestEvaluation from 'views/latest'
import Testing from 'views/testing'
import Demo from 'views/courses/Demo'



import PATH from 'utils/path'
import addParameters from './utils'
import NotificationsView from 'views/notifications'
import Stats from 'views/stats'

const defaultRouterConfig = {
  disableNavigationTour: false,
  injectModelsBeforeRender: false,
  exact: true
}

const defaultParams = [
  'id'
]

/**
 * @typedef {Object} RouteParameters
 * @property {import ('react').Component} component
 * @property {string} path
 * @property {boolean} exact
 */

/**
 * @type {{ routes: { public: Array<RouteParameters>, private: Array<RouteParameters> }}}
 */
export const router = {
  routes: {
    public: [
      {
        ...defaultRouterConfig,
        component: Main,
        path: '/'
      },
      {
        ...defaultRouterConfig,
        component: Verification,
        path: PATH.VERIFICATION
      },
      {
        ...defaultRouterConfig,
        component: SignUp,
        path: PATH.SIGN_UP
      },
      {
        ...defaultRouterConfig,
        component: Login,
        path: PATH.LOGIN
      },
      {
        ...defaultRouterConfig,
        component: ResetPassword,
        path: PATH.RESET
      }
    ],
    private: [
      {
        ...defaultRouterConfig,
        injectModelsBeforeRender: true,
        component: Classes,
        path: PATH.CLASSES,
      },
      {
        ...defaultRouterConfig,
        component: Gifts,
        path: PATH.GIFT
      },
      {
        ...defaultRouterConfig,
        injectModelsBeforeRender: true,
        component: Agreement,
        path: PATH.AGREEMENT
      },
      {
        ...defaultRouterConfig,
        component: Dashboard,
        injectModelsBeforeRender: true,
        path: PATH.DASHBOARD,
        disableNavigationTour: true
      },
      {
        ...defaultRouterConfig,
        component: Exercise,
        injectModelsBeforeRender: true,
        path: PATH.EXERCISES
      },
      {
        ...defaultRouterConfig,
        component: Feedback,
        injectModelsBeforeRender: true,
        path: PATH.FEEDBACK
      },
      {
        ...defaultRouterConfig,
        component: Logout,
        path: PATH.LOGOUT
      },
      {
        ...defaultRouterConfig,
        component: Settings,
        path: PATH.SETTINGS
      },
      {
        ...defaultRouterConfig,
        component: SettingsPassword,
        path: PATH.PASSWORD
      },
      {
        ...defaultRouterConfig,
        injectModelsBeforeRender: true,
        component: Plans,
        path: PATH.PAYMENTS
      },
      {
        ...defaultRouterConfig,
        injectModelsBeforeRender: true,
        component: Evaluations,
        path: addParameters(PATH.EVALUATION, defaultParams)
      },
      {
        ...defaultRouterConfig,
        component: Exchange,
        path: PATH.EXCHANGE
      },
      {
        ...defaultRouterConfig,
        component: Courses,
        path: PATH.COURSES
      },
      {
        ...defaultRouterConfig,
        component: Error402,
        path: PATH.REQUIRED
      },
      {
        ...defaultRouterConfig,
        component: FetchError,
        path: PATH.ERROR
      },
      {
        ...defaultRouterConfig,
        component: AdminSchedule,
        path: PATH.CREATE_MEETING
      },
      {
        ...defaultRouterConfig,
        component: Meetings,
        path: PATH.MEETINGS
      },
      {
        ...defaultRouterConfig,
        component: Quality,
        path: PATH.QUALITY
      },
      {
        ...defaultRouterConfig,
        component: Demo,
        path: PATH.DEMO
      },
      {
        ...defaultRouterConfig,
        component: Models,
        path: PATH.MODELS
      },
      {
        ...defaultRouterConfig,
        component: Exams,
        path: PATH.EXAMS
      },
      {
        ...defaultRouterConfig,
        component: LatestEvaluation,
        path: addParameters(PATH.LATEST, defaultParams)
      },
      {
        ...defaultRouterConfig,
        component: NotificationsView,
        path: PATH.NOTIFICATIONS
      },
      {
        ...defaultRouterConfig,
        component: Stats,
        path: PATH.STATS
      }
    ]
  },
  development: [
    {
      ...defaultRouterConfig,
      component: Testing,
      path: '/testing'
    }
  ]
}
