import httpClient, { GET, POST, PUT } from 'providers/http'

/**
 * @param {string} model
 * @param {boolean | null} demo
 * @param {AbortSignal} signal 
 */
export function fetchCourses (model, demo, signal) {
  return httpClient({
    endpoint: '/api/v1/courses',
    method: GET,
    queries: {
      demo,
      model
    },
    requiresAuth: true,
    signal
  })
}

/**
 * @param {number} course
 * @param {AbortSignal} signal
 */
export function fetchAdvance (course, signal) {
  return httpClient({
    endpoint: '/api/v1/advance',
    method: GET,
    queries: {
      course
    },
    requiresAuth: true,
    signal
  })
}

/**
 * @param {string} resource
 * @param {AbortSignal} signal 
 */
export function fetchCourse (resource, signal) {
  return httpClient({
    endpoint: resource,
    external: true,
    method: GET,
    signal
  })
}


export function updateAdvance (advance) {
  return httpClient({
    body: advance,
    endpoint: '/api/v1/advance',
    method: PUT,
    requiresAuth: true
  })
}


export function createAdvance (advance) {
  return httpClient({
    body: advance,
    endpoint: '/api/v1/advance',
    method: POST,
    requiresAuth: true
  })
}