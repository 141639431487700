export const SET_AUDIO_TRACKS = 'SET_AUDIO_TRACKS'

export const SET_VIDEO_TRACKS = 'SET_VIDEO_TRACKS'

export const REMOVE_AUDIO_TRACKS = 'REMOVE_AUDIO_TRACKS'

export const REMOVE_VIDEO_TRACKS = 'REMOVE_VIDEO_TRACKS'

export const SET_AUDIO_TRACKS_STREAM = 'SET_AUDIO_TRACKS_STREAM'

export const SET_VIDEO_TRACKS_STREAM = 'SET_VIDEO_TRACKS_STREAM'

export const SET_MICROPHONE_STATE = 'SET_MICROPHONE_STATE'

export const SET_CAMERA_STATE = 'SET_CAMERA_STATE'

export const SET_DEVICES = 'SET_DEVICES'

export const SET_VIDEO_DEVICE = 'SET_VIDEO_DEVICE'

export const SET_AUDIO_DEVICE = 'SET_AUDIO_DEVICE'

export const SET_SHARESCREEN_STATE = 'SET_SHARESCREEN_STATE'

export const SET_REMOTE_IDENTITY = 'SET_REMOTE_IDENTITY'