import httpClient, { GET, POST, PUT } from 'providers/http'

/**
 * @typedef {Object} Controller
 * @property {AbortSignal} signal
 */

const endpoint = '/api/v1/plans'

/**
 * @description
 * Creates a plan through http request.
 * @param {Controller}
 */
export function createPlan({ signal, plan }) {
  return httpClient({
    body: plan,
    endpoint,
    method: POST,
    requiresAuth: true,
    signal
  })
}

/**
 * @description
 * Updates a plan through http request.
 * @param {Controller}
 */
export function updatePlan({ signal, plan }) {
  return httpClient({
    body: plan,
    endpoint,
    method: PUT,
    requiresAuth: true,
    signal
  })
}

/**
 * Get plans through http request.
 * @param {Controller}
 */
export function fetchPlans({ signal, ...data }) {
  return httpClient({
    endpoint,
    method: GET,
    requiresAuth: true,
    queries: data,
    signal
  })
}


